import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { setError } from "src/redux/slices/errorSlice";
import {
  removeOrderVariantError,
  setOrderVariantError,
} from "src/redux/slices/ordering/orderSetSlice";

import { useStatesQuery } from "@features/states";
import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";
import { OrderVariant } from "@models/OrderVariant";
import client from "@services/api";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import useOrderSetId from "../useOrderSetId";

const useWarehouseTaxRate = () => {
  const { data } = useStatesQuery({ filter: { code: "IL" } });
  const illinoiseTaxRate = data?.[0]?.taxRate ?? 9.08;
  return illinoiseTaxRate;
};

const calcNewOrderVariantCosts = (
  oldOrderVariant: OrderVariant,
  newCost: number,
  taxRate: number,
  shippingPercent: number
) => {
  const newTotalCost = oldOrderVariant.qty * newCost;

  return {
    estimatedCost: String(newCost),
    totalEstimatedCost: String(newTotalCost),
    totalEstimatedTax: String((newTotalCost * taxRate) / 100),
    totalEstimatedShippingCost: String(newTotalCost * shippingPercent),
  };
};

type SetOrderVariantQtyPayload = {
  id: string;
  qty: number;
};

export default function useSetOrderVariantQty() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();

  const warehouseTaxRate = useWarehouseTaxRate();

  const startOrderSet = useCallback(async () => {
    client.post(`order-sets/${orderSetId}/start`).catch((err) => {
      console.error(err);
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    });
    // Optimistic update
    queryClient.setQueryData(
      orderSetsKeyFactory.detail(orderSetId).queryKey,
      (orderSet: OrderSet) => ({
        ...orderSet,
        status: "in-progress",
      })
    );
  }, [orderSetId, queryClient]);

  return useMutation({
    mutationFn: ({ id, qty }: SetOrderVariantQtyPayload) => {
      const orderSet = queryClient.getQueryData<OrderSet>(
        orderSetsKeyFactory.detail(orderSetId).queryKey
      )!;

      if (orderSet.status === "inactive") {
        startOrderSet();
      }

      return client
        .update<OrderVariant>(`order-variants/${id}`, {
          type: "order-variant",
          qty,
        })
        .then((data) => ({ orderVariant: data.data, orderSet }));
    },
    onSuccess: ({ orderVariant: newOrderVariant, orderSet }) => {
      dispatch(removeOrderVariantError({ id: newOrderVariant.id }));

      const newBudgets = _.keyBy(newOrderVariant.budgets, "id");

      const osVariant = orderSet.orderSetVariants.find(
        (osv) => osv.variant.id === newOrderVariant.variant.id
      )!;
      const pricingTierChange =
        osVariant.estimatedCost !== newOrderVariant.estimatedCost;

      if (pricingTierChange) {
        queryClient.setQueryData<OrderSet>(
          orderSetsKeyFactory.detail(orderSetId).queryKey,
          (orderSet) =>
            orderSet && {
              ...orderSet,
              orderSetVariants: orderSet.orderSetVariants.map((osv) => {
                if (osv.variant.id === newOrderVariant.variant.id) {
                  return {
                    ...osv,
                    estimatedCost: newOrderVariant.estimatedCost,
                  };
                }
                return osv;
              }),
            }
        );
      }
      return queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
        (orders: Order[]) => {
          return orders.map((order) => {
            // There are two cases where we want to update the orderVariant:
            // 1. We're replacing this order-variant with the new updated one.
            // 2. The pricing tier of the variant for this order-set has changed, all order-variants for this variant need to be updated
            let replacedOVCopy: any;
            let modifiedOV = newOrderVariant;
            const orderVariants = order.orderVariants.map((ov) => {
              // We're replacing this order-variant with the new updated one.
              if (ov.id === newOrderVariant.id) {
                replacedOVCopy = ov;
                return newOrderVariant;
              }

              // The pricing tier of the variant for this order-set has changed, all order-variants for this variant need to be updated
              if (
                pricingTierChange &&
                ov.variant.id === newOrderVariant.variant.id
              ) {
                replacedOVCopy = ov;
                modifiedOV = {
                  ...ov,
                  ...calcNewOrderVariantCosts(
                    ov,
                    +newOrderVariant.estimatedCost,
                    +(order.address?.state.taxRate ?? warehouseTaxRate),
                    +orderSet.estimatedShippingPercent
                  ),
                  variablePricingMinRangeValue:
                    newOrderVariant.variablePricingMinRangeValue,
                  variablePricingMaxRangeValue:
                    newOrderVariant.variablePricingMaxRangeValue,
                  budgets: ov.budgets.map((b) => newBudgets[b.id] ?? b),
                };
                return modifiedOV;
              }

              // This doesn't match the variant, but we might still have budgets in common, so update them
              return {
                ...ov,
                budgets: ov.budgets.map((b) => newBudgets[b.id] ?? b),
              };
            });

            let updatedOrderTotals = {};

            if (replacedOVCopy) {
              const updateKey = (key: string) =>
                String(+order[key] - +replacedOVCopy[key] + +modifiedOV[key]);

              updatedOrderTotals = {
                totalQuantity:
                  order.totalQuantity - replacedOVCopy.qty + modifiedOV.qty,
                totalBeaconCost: updateKey("totalBeaconCost"),
                totalEstimatedCost: updateKey("totalEstimatedCost"),
                totalEstimatedTax: updateKey("totalEstimatedTax"),
                totalEstimatedShippingCost: updateKey(
                  "totalEstimatedShippingCost"
                ),
              };
            }

            return {
              ...order,
              ...updatedOrderTotals,
              orderVariants,
            };
          });
        }
      );
    },
    onError: (error: any, { id }) => {
      if (Array.isArray(error.data.errors)) {
        const message = error.data.errors[0]?.title;
        // matches a number in parentheses at the end of the string
        const maxQuantity = message.match(/\((\d+)\)$/)?.[1];
        dispatch(setOrderVariantError({ id, error: message, maxQuantity }));
      } else {
        dispatch(
          setError({
            error: error.message,
            source: "useSetOrderVariantQty",
          })
        );
        console.error(error);
      }
    },
  });
}
