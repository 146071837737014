/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Add, Close, Remove } from "@mui/icons-material";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { VariantCategoryName } from "@models/VariantOption";
import { CldVideo } from "@services/cloudinary";

import {
  NON_SUPPLIER_ROLES,
  PLANNING_TOOL_ROLES,
} from "../../constants/permissions";
import {
  clearVariablePricingDataExport,
  fetchVariablePricingData,
} from "../../redux/slices/variablePricing/variablePricingSlice";
import {
  formatDateString,
  formatMoney,
  formatMoneyString,
} from "../../utility/utilityFunctions";
import Carousel from "../Carousel";
import { BeaconIcon } from "../StyledComponents";
import ImageWrapper from "../Utility/ImageWrapper";

const Tag = tw.div`px-3 py-2 text-sm rounded box-border bg-neutral-50 text-neutral-700 border border-neutral-300`;

const Title = tw.div`uppercase text-sm text-neutral-900 tracking-wider mb-2`;

const LabelValuePair = ({ label, value }) => (
  <div tw="flex px-2 py-1 rounded transition border border-transparent hover:(bg-neutral-50 border-neutral-300)">
    <div tw="flex-none mr-2 w-48 text-neutral-600 font-light">{label}:</div>
    <span>{value}</span>
  </div>
);
const useStyles = makeStyles((theme) => ({
  ...theme.global,
  largeImageWrapper: {},
  largeImage: {
    margin: "0 auto",
    width: "400px",
    minHeight: "400px",
    maxHeight: "600px",
    objectFit: "contain",
  },
}));

const ItemPreviewModal = ({
  type,
  currentItem: item,
  handleClose,
  previewModal: open,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showAllPrograms, setShowAllPrograms] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Item Info");
  const [isFullSpec, setIsFullSpec] = useState(false);

  const currentUserRole = useSelector((state) => state.user.role);
  const {
    data: varPricingData,
    isLoading,
    errors: varPricingErrors,
  } = useSelector((state) => state.variablePricing);

  const currentBrands =
    typeof item.brand === "string" ? item.brand.split(", ") : [...item.brand];

  // Gotta do this because of the compliance items preview
  const currentPrograms =
    item.programs ??
    (typeof item.program === "string"
      ? item.program.split(", ")
      : [...item.program]
    ).map((name) => ({ name }));

  const currentSpec = isFullSpec
    ? item.fullSpecification
    : item.specification ?? [];

  let mappedSpec =
    currentSpec[0]?.type === "websiteDesc"
      ? Object.entries(currentSpec[0].value).map(([key, value]) => ({
          key,
          value,
        }))
      : currentSpec;

  mappedSpec = mappedSpec.filter(({ key }) => key !== "MOQ" && key !== "MOQ:");

  const amountAvailable = !item.warehouse
    ? "Currently Unavailable"
    : item.stock <= 0
    ? "Out of Stock"
    : item.stock;

  const info = {
    Type: item.itemType,
    Description: item.itemDescription,
    ...(item.orderType && { "Order Status": item.orderType }),
    MOQ: item.moq ?? "---",
    "Pack Size": item.packSize,
    ...(item.orderType === "In Stock" && {
      "Amount available": amountAvailable,
    }),
    ...(item.removeFromCatalogDate && {
      "Available to order until": formatDateString(item.removeFromCatalogDate),
    }),
    "Has Beacon": item.includeBeacon ? (
      <div tw="flex gap-1 items-center">
        Yes <BeaconIcon />
      </div>
    ) : (
      "No"
    ),
    ...(item.includeBeacon && {
      "Beacon Cost": formatMoneyString(item.mostRecentBeaconCost),
    }),
  };

  const hasFullSpecsPermissions = NON_SUPPLIER_ROLES.includes(currentUserRole);
  const hasProgramPermissions = PLANNING_TOOL_ROLES.includes(currentUserRole);

  const handleModalClose = () => {
    handleClose();
  };

  useEffect(() => {
    dispatch(
      fetchVariablePricingData(item.id, Boolean(item.standardSpecificationCode))
    );
    return () => dispatch(clearVariablePricingDataExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={handleModalClose}
        disableScrollLock
        fullWidth
        scroll="paper"
        maxWidth="lg"
        style={{ zIndex: "15000" }}
      >
        <DialogContent tw="grid lg:grid-cols-2 gap-4 p-0">
          <IconButton
            tw="absolute top-1 right-1 z-10"
            onClick={handleModalClose}
            size="large"
          >
            <Close color="secondary" />
          </IconButton>
          <div tw="flex items-center justify-center p-4">
            <Carousel imageIdsOrUrls={item.imgUrlLg}>
              {item.imgUrlLg?.map((url, index) => (
                <div className={classes.largeImageWrapper} key={index}>
                  {url.includes("/video/") ? (
                    <CldVideo
                      cloudinaryId={url.match(/(?<=\/upload\/)[^.]+/)[0]}
                    />
                  ) : (
                    <ImageWrapper
                      imgUrl={url}
                      alt={`${item.brand} ${item.itemType}`}
                      imgClass={classes.largeImage}
                      id={item.itemNumber}
                    />
                  )}
                </div>
              ))}
            </Carousel>
          </div>
          <div tw="lg:max-h-full lg:overflow-y-auto text-base text-neutral-800">
            <Typography
              component="div"
              tw="max-w-prose my-12 p-4 mx-auto lg:mx-0"
            >
              <div tw="text-neutral-500"># {item.itemNumber}</div>
              <h1 tw="text-3xl mt-2">
                {item.itemDescription === "---"
                  ? item.itemType
                  : item.itemDescription}
              </h1>
              <div tw="mt-3 flex items-center text-neutral-400">
                <div tw="font-bold tracking-wider text-neutral-600">
                  {formatMoney(item.estCost)}
                </div>
                &nbsp; / &nbsp;
                <div tw="">
                  {type === "inventory"
                    ? `${item.stock} in stock`
                    : item.orderType}
                </div>
              </div>

              <Title tw="mt-6">{`Brand${
                currentBrands.length !== 1 ? "s" : ""
              }`}</Title>
              <div tw="flex flex-wrap items-start gap-2">
                {currentBrands.map((brand, i) => (
                  <>
                    {/* Add a comma and space if it's not the first brand */}
                    {i > 0 && ", "}
                    <Typography variant="subtitle1">{brand}</Typography>
                  </>
                ))}
              </div>
              <div tw="flex justify-between items-center">
                <Title tw="mt-6">{`Program${
                  currentPrograms.length !== 1 ? "s" : ""
                }`}</Title>
                {currentPrograms.length > 2 && (
                  <IconButton
                    onClick={() => setShowAllPrograms(!showAllPrograms)}
                  >
                    {showAllPrograms ? <Remove /> : <Add />}
                  </IconButton>
                )}
              </div>
              <div
                css={
                  showAllPrograms
                    ? tw`flex flex-wrap items-start gap-2`
                    : tw`relative flex items-start gap-2 overflow-x-hidden
                    after:(absolute inset-0 left-auto w-16 bg-gradient-to-r from-transparent via-white to-white pointer-events-none)`
                }
              >
                {currentPrograms.map((program, i) => (
                  <Link
                    to={program.id ? `/planning/program/${program.id}` : "#"}
                    disabled={!program.id || !hasProgramPermissions}
                    tw="block whitespace-nowrap"
                    target={program.id && "_blank"}
                    key={i}
                  >
                    <Tooltip
                      title={
                        +program.id === item.mostRecentProgramId
                          ? "Pricing Reference Program"
                          : ""
                      }
                      componentsProps={{ popper: { style: { zIndex: 15001 } } }}
                    >
                      <Tag
                        css={
                          +program.id === item.mostRecentProgramId &&
                          tw`shadow-[inset 0px 0px 0px 2px] shadow-blue-500`
                        }
                      >
                        {program.name}
                      </Tag>
                    </Tooltip>
                  </Link>
                ))}
              </div>
              <div tw="flex mt-6 mb-2">
                {/* "Item Info" Tab Button */}
                <button
                  css={[
                    tw`relative px-3 py-2 mr-4 transition-all duration-300 transform`,
                    tw`hover:border-blue-500`,
                    selectedTab === "Item Info"
                      ? tw`border-b-2 border-blue-500 `
                      : tw`border-b-2 border-neutral-300`,
                  ]}
                  onClick={() => setSelectedTab("Item Info")}
                >
                  ITEM INFO
                </button>

                {/* "Tiered Pricing" Tab Button */}
                {!varPricingErrors ? (
                  !isLoading ? (
                    varPricingData.length > 0 && (
                      <button
                        css={[
                          tw`relative px-3 py-2 transition-all duration-300 transform`,
                          tw`hover:border-blue-500`,
                          selectedTab === "Tiered Pricing"
                            ? tw`border-b-2 border-blue-500 `
                            : tw`border-b-2 border-neutral-300`,
                        ]}
                        onClick={() => setSelectedTab("Tiered Pricing")}
                      >
                        TIERED PRICING
                      </button>
                    )
                  ) : (
                    <CircularProgress />
                  )
                ) : (
                  <Typography tw="flex items-center italic text-neutral-400 text-sm">
                    {varPricingErrors}
                  </Typography>
                )}
              </div>

              {selectedTab === "Item Info" ? (
                <>
                  {Object.entries(info).map(([key, value], i) => (
                    <LabelValuePair key={i} label={key} value={value} />
                  ))}
                  {item.variants &&
                    item.variants.filter((v) => v.name).length > 0 && (
                      <>
                        <Title tw="mt-6">Variants</Title>
                        {item.variants.filter((v) => v.isActive && v.name)
                          .length === 0 ? (
                          <Typography tw="text-neutral-600 font-light ml-2">
                            No variants
                          </Typography>
                        ) : (
                          <div tw="flex px-2 py-1">
                            <div tw="flex-none mr-2 w-48">Size</div>
                            <span tw="flex-none mr-2 w-48">Team</span>
                          </div>
                        )}
                        {item.variants.map((variant) => {
                          if (variant.name && variant.isActive) {
                            const parts = variant.name.split(",");
                            let size = null;
                            let sport = null;
                            parts.forEach((part) => {
                              if (part.includes(VariantCategoryName.Sizes)) {
                                size = part.split(":")[1].trim();
                              } else if (
                                part.includes(VariantCategoryName.Sports)
                              ) {
                                sport = part.split(":")[1].trim();
                              }
                            });
                            return (
                              <div tw="flex px-2 py-1 rounded transition border border-transparent hover:(bg-neutral-50 border-neutral-300)">
                                <div tw="flex-none mr-2 w-48 text-neutral-600 font-light">
                                  {size}
                                </div>
                                <span tw="text-neutral-600 font-light">
                                  {sport}
                                </span>
                              </div>
                            );
                          }
                          return null;
                        })}
                        <br />
                      </>
                    )}
                </>
              ) : (
                <>
                  {varPricingData.length > 0 ? (
                    <>
                      <Typography tw="my-4 ml-2">
                        Standard Spec. Code: {varPricingData[0].stdSpecCode}
                      </Typography>
                      <Table size="small">
                        <TableHead style={{ background: "whitesmoke" }}>
                          <TableRow>
                            <TableCell tw="text-right">Min. Qty</TableCell>
                            <TableCell tw="text-right">Max. Qty</TableCell>
                            <TableCell tw="text-right">Unit Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {varPricingData.map((row, _) => (
                            <TableRow key={row.id} tw="text-right">
                              <TableCell tw="text-right">
                                {row.minValue ?? ""}
                              </TableCell>
                              <TableCell tw="text-right">
                                {row.maxValue ?? ""}
                              </TableCell>
                              <TableCell tw="text-right">
                                {row.unitPrice
                                  ? formatMoneyString(row.unitPrice)
                                  : ""}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  ) : (
                    <Typography>
                      No variable pricing data for this item
                    </Typography>
                  )}
                </>
              )}

              <div tw="mt-6 flex justify-between items-center">
                <Title>Specifications</Title>
                {hasFullSpecsPermissions &&
                  item.fullSpecification?.length >
                    item.specification?.length && (
                    <IconButton
                      onClick={() => setIsFullSpec(!isFullSpec)}
                      title={isFullSpec ? "Less" : "More"}
                    >
                      {isFullSpec ? <Remove /> : <Add />}
                    </IconButton>
                  )}
              </div>
              <div>
                {mappedSpec.map(({ key, value }, i) => {
                  return (
                    <LabelValuePair
                      key={i}
                      label={key.replace(":", "")}
                      value={
                        <span
                          css={
                            (!value || value.toLowerCase() === "n/a") &&
                            tw`opacity-40`
                          }
                        >
                          {value || "---"}
                        </span>
                      }
                    />
                  );
                })}
              </div>
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ItemPreviewModal.propTypes = {
  type: PropTypes.string,
  currentItem: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ItemPreviewModal;
