import { useSelector } from "react-redux";

import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const anaplanProgramTypes = [
  { id: "all", label: "All" },
  { id: "national", label: "National" },
  { id: "catch-all", label: "Catch All" },
  { id: "forward-deployed-created", label: "Forward Deployed Created" },
];

const AnaplanProgramTypeSelector = ({
  handleAnaplanProgramType,
  classes,
  filterType,
}) => {
  const currentType = useSelector(
    (state: any) => state.filters.anaplanProgramType
  );
  const isGlobalLoading = useSelector(
    (state: any) => state.globalLoad.isLoading
  );

  const handleChangeSelect = (evt) => {
    handleAnaplanProgramType(
      evt.target.value,
      "anaplanProgramType",
      filterType
    );
  };

  return (
    <>
      <FormControl
        fullWidth
        size="small"
        variant="outlined"
        className={classes.queryField}
        disabled={isGlobalLoading}
      >
        <InputLabel>Anaplan Program Type</InputLabel>
        <Select
          variant="outlined"
          label={"Anaplan Program Type"}
          name="anaplanProgramType"
          value={currentType || ""}
          onChange={handleChangeSelect}
          MenuProps={{
            style: { zIndex: "2500" },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {anaplanProgramTypes.map((anaProgType) => (
            <MenuItem value={anaProgType.id} key={anaProgType.id}>
              <Typography variant="body2">{anaProgType.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default AnaplanProgramTypeSelector;
