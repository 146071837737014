import { AxiosError, isAxiosError } from "axios";
import { H } from "highlight.run";

export default function consumeAxiosError(error: Error | AxiosError, caller) {
  if (!isAxiosError(error) || !error.response) return Promise.reject(error);
  const hitsRender = !!error.response.headers["rndr-id"];
  const apiUrl = error.request.responseURL.includes("readytoactivate");
  const status = error.response.status;
  let msg: any[] = [];
  msg.push(apiUrl ? "PROD" : "DEV");
  msg.push(error.config?.method?.toUpperCase());
  msg.push(status.toString());
  msg.push(hitsRender ? "has rndr-id" : "CF only");

  H.consumeError(error, msg.filter((c) => c).join(" "), {
    caller,
    status: error.response.status,
    responseHeaders: error.response.headers,
    method: error.config?.method,
    url: error.request.responseURL,
  } as any);
  return Promise.reject(error);
}
