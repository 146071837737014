/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useMemo, useState } from "react";

import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
} from "@mui/material";

import { debounce } from "lodash";
import { DefaultTextField } from "src/components/Forms/DefaultInputs";

import { routeResourceTypes, useApiResource } from "@services/api";

export type ResourceAutocompleteProps<
  TResourceName extends keyof routeResourceTypes,
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, false>,
  "options" | "renderInput"
> & {
  resource: TResourceName;
  resourceFilterName?: string;
  label?: string;
  getOptionText?: (option: T) => string;
  requestParams?: Record<string, any>;
  fetchOnMount?: boolean;
  /* Tap options lets us modify the resource data before it's given as options */
  tapOptions?: (options: T[]) => T[];
  skipPagination?: boolean;
  required?: boolean;
};

export const ResourceAutocomplete = <
  TResourceName extends keyof routeResourceTypes,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
>({
  label,
  resource,
  resourceFilterName = "name",
  requestParams,
  value,
  onChange,
  fetchOnMount = false,
  getOptionText,
  tapOptions = (opts) => opts,
  skipPagination = false,
  ...props
}: ResourceAutocompleteProps<
  TResourceName,
  routeResourceTypes[TResourceName],
  Multiple,
  DisableClearable
>) => {
  const [searchString, setSearchString] = useState<string>("");
  const [fetchData, setFetchData] = useState(fetchOnMount);
  const { data, isLoading } = useApiResource(fetchData && resource, {
    params: {
      ...requestParams,
      filter: {
        ...(!skipPagination &&
          resourceFilterName && { [resourceFilterName]: searchString }),
        ...requestParams?.filter,
      },
      ...(skipPagination && { skipPagination: true }),
    },
    keepPreviousData: true,
    focusThrottleInterval: 10_000,
  });

  let options = data ?? ([] as routeResourceTypes[TResourceName][]);
  options = tapOptions(options);
  options = getOptionText
    ? options.map((opt) => ({ ...opt, name: getOptionText(opt) }))
    : options;

  const debouncedSearchString = useMemo(
    () => debounce(setSearchString, 500),
    []
  );

  const getOpObj = (option) =>
    option?.id ? option : options.find((o) => o.id === option);

  useEffect(() => {
    if (props.multiple && Array.isArray(value) ? value.length : value) {
      setFetchData(true);
    }
  }, [props.multiple, value]);

  return (
    <Autocomplete
      size="small"
      value={value}
      options={options}
      onInputChange={(_, val) => debouncedSearchString(val)}
      onChange={onChange}
      onOpen={() => setFetchData(true)}
      isOptionEqualToValue={(option, value) =>
        option.id === getOpObj(value)?.id
      }
      getOptionLabel={(option) =>
        getOpObj(option)?.name ?? (isLoading ? "loading..." : "")
      }
      slotProps={{
        popper: { style: { zIndex: 999999 }, placement: "bottom-start" },
      }}
      renderOption={(props: any, option: any) => (
        <li {...props} tw="whitespace-nowrap" key={option.id}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <DefaultTextField
          {...params}
          label={label}
          required={props.required}
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            endAdornment: (
              <>
                {isLoading ? <CircularProgress size={15} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};
