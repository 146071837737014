/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";
import PropTypes from "prop-types";

import { useCurrentRegionalTerritorySubStates } from "@features/states";
import PaperTooltip from "@features/ui/PaperTooltip";
import { useApiResource } from "@services/api";

import {
  clearItemSelections,
  updateSelection,
} from "../../redux/slices/ordering/currentOrderSlice";
import { formatMoney } from "../../utility/utilityFunctions";
import { BeaconIcon } from "../StyledComponents";
import ImageWrapper from "../Utility/ImageWrapper";

const VariantAllocationTooltip = ({ variants, children }) => {
  const { currentTerritory, territories } = useSelector((state) => state.user);

  const currentTerritoryObj = territories.find(
    (t) => t.id === currentTerritory
  );

  const isRegional =
    currentTerritoryObj && currentTerritoryObj.type === "Regional";

  const useSubstates =
    !currentTerritoryObj ||
    (currentTerritoryObj && currentTerritoryObj.type !== "Customer");

  // If current territory is regional, get substates for the territory
  const { data: subStateData } = useApiResource(useSubstates && "sub-states", {
    ...(isRegional && { filter: { "territory-id": currentTerritoryObj.id } }),
    staleTime: Infinity,
  });

  let subStateIds = [];
  if (subStateData) {
    subStateIds = subStateData.map((subState) => subState.id);
  }

  // Get variant allocations for territory if current territory type is Customer, otherwise get for substates
  const territoryVariantAllocations = variants
    .flatMap((v) => v.variantAllocations)
    .filter((va) =>
      useSubstates
        ? subStateIds.includes(va.subStateId)
        : va.territoryId === currentTerritory
    );

  return (
    <PaperTooltip
      title={
        <>
          <div tw="flex justify-center text-lg font-medium mb-4">
            State/Territory Breakdown
          </div>
          <div tw="grid grid-cols-2 gap-2 overflow-auto max-h-64">
            {territoryVariantAllocations.length > 0 ? (
              <>
                <div tw="font-bold">State/Territory</div>
                <div tw="font-bold">Qty. Available to Order</div>
                {territoryVariantAllocations.map((va, index) => (
                  <React.Fragment key={index}>
                    <div>{va.subStateName ?? va.territoryName}</div>
                    <div>{va.availableToOrderQty}</div>
                  </React.Fragment>
                ))}
              </>
            ) : (
              <div tw="col-span-2 text-center">No allocations available</div>
            )}
          </div>
        </>
      }
      placement="top-start"
    >
      {children}
    </PaperTooltip>
  );
};

const headCells = [
  { id: "preview", label: "Preview", sort: false },
  { id: "itemNumber", label: "Sequence #", sort: true },
  { id: "brand", label: "Brand", sort: false },
  { id: "program", label: "Program", sort: false },
  { id: "itemType", label: "Item Type", sort: false },
  { id: "itemDescription", label: "Item Desc.", sort: false },
  { id: "packSize", label: "Pack Size", sort: false },
  { id: "stock", label: "On Hand Nat.", sort: true },
  { id: "terrStock", label: "On Hand Terr.", sort: false },
  { id: "estCost", label: "Est. Cost", sort: false },
];

const EnhancedTableHead = (props) => {
  const {
    classes,
    rowCount,
    onSelectAllClick,
    numSelected,
    orderLength,
    type,
    role,
    email,
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const currentHeadCells =
    type === "inventory"
      ? role === "purchaser" || role === "super" || role === "select-purchaser"
        ? headCells
        : role === "field2" &&
          [
            "molly.swopes@ejgallo.com",
            "felicia.livengood@ejgallo.com",
            "field2a@gallo.com",
          ].includes(email.toLowerCase())
        ? headCells.filter((cell) => cell.id !== "addInv")
        : headCells.filter(
            (cell) => cell.id !== "addInv" && cell.id !== "addShelfInv"
          )
      : headCells.filter(
          (cell) =>
            cell.id !== "stock" &&
            cell.id !== "terrStock" &&
            cell.id !== "addInv" &&
            cell.id !== "addShelfInv"
        );

  return (
    <TableHead css={{ th: tw`whitespace-nowrap` }}>
      <TableRow>
        {!["read-only", "compliance"].includes(role) && (
          <TableCell padding="checkbox" align="center">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount - orderLength}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all items" }}
            />
            <div tw="m-1 -mt-2 font-normal text-neutral-500">Select all</div>
          </TableCell>
        )}
        {currentHeadCells.map((headCell) => {
          if (!headCell.sort) {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
              >
                {headCell.label}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  orderLength: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  buttonText: {
    whiteSpace: "nowrap",
  },
}));

const OrderVariantTableView = ({
  type,
  currentItems,
  handlePreview,
  setCurrentItemAdded,
  isItemsLoading,
  scrollRef,
  handleSort,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formattedType = `selected${type[0].toUpperCase() + type.slice(1)}Items`;

  const [order, setOrder] = useState(null);
  const [orderBy, setOrderBy] = useState(null);

  const selectedItems = useSelector(
    (state) => state.currentOrder[formattedType]
  );
  const currentOrderVariants = useSelector(
    (state) => state.currentOrder[`${type}OrderVariants`]
  );
  const { role, email, currentTerritory } = useSelector((state) => state.user);

  const { isRegional, subStateIds } = useCurrentRegionalTerritorySubStates();

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSort({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleSelectAllClick = (event) => {
    //todo update to handle warehouse and reimplement
    if (event.target.checked) {
      const newSelecteds = [];
      currentItems.forEach((item) => {
        if (
          currentOrderVariants.filter((v) => item.itemNumber === v.itemNumber)
            .length === 0 &&
          !handleCheckBoxDisable(item)
        ) {
          newSelecteds.push(item.id);
        }
      });
      //const newSelecteds = currentItems.map((item) => item.id);
      dispatch(
        updateSelection({ type: formattedType, selectedItems: newSelecteds })
      );
      return;
    }
    dispatch(clearItemSelections());
  };

  const handleClick = (_event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }
    dispatch(
      updateSelection({ type: formattedType, selectedItems: newSelected })
    );
  };

  const isSelected = (id) => selectedItems.indexOf(id) !== -1;

  const handleTerritoryQtys = (variants) => {
    const allocations = variants.flatMap((v) => v.variantAllocations);
    const matchingAllocations = allocations.filter((t) =>
      isRegional
        ? subStateIds.includes(t.subStateId)
        : t.territoryId === currentTerritory
    );
    if (matchingAllocations.length > 0) {
      return _.sumBy(matchingAllocations, "qty");
    } else return "---";
  };

  const handleCheckBoxDisable = (row) => {
    let currentVariantIds = currentOrderVariants.map((v) => v.variantId);
    if (
      row.variants.map((v) => v.id).every((v) => currentVariantIds.includes(v))
    ) {
      return "In Cart";
    } else if (type === "inventory" && !row.warehouse) {
      return "Unavailable";
    } else if (type === "inventory" && row.stock <= 0) {
      let terrQty = handleTerritoryQtys(row.variants);
      if (terrQty && terrQty !== "---" && terrQty > 0) {
        return null;
      } else if (row.poInMarketDate) {
        return `Out of Stock, Avail. ${row.poInMarketDate}`;
      } else {
        return "Out of Stock";
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 250px)" }}
        ref={scrollRef}
      >
        <Table className={classes.table} aria-label="item-table" stickyHeader>
          <EnhancedTableHead
            classes={classes}
            numSelected={selectedItems.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={currentItems.length}
            orderLength={currentOrderVariants.length}
            type={type}
            role={role}
            email={email}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {!isItemsLoading && currentItems.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={type === "inventory" ? 11 : 9}>
                  <Typography className={classes.headerText}>
                    {`There are no items that match the current search criteria..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isItemsLoading &&
              currentItems.length > 0 &&
              currentItems.map((row, index) => {
                const isItemSelected = !["read-only", "compliance"].includes(
                  role
                )
                  ? isSelected(row.id)
                  : null;
                const labelId = `item-Checkbox-${index}`;

                return (
                  <TableRow key={row.id} hover>
                    {!["read-only", "compliance"].includes(role) && (
                      <TableCell padding="checkbox" align="center">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => event.stopPropagation()}
                          disabled={Boolean(handleCheckBoxDisable(row))}
                          onChange={(event) => {
                            handleClick(event, row.id);
                            event.stopPropagation();
                          }}
                        />
                        <FormHelperText>
                          {handleCheckBoxDisable(row)}
                        </FormHelperText>
                      </TableCell>
                    )}
                    <TableCell align="left">
                      <div tw="relative">
                        <ImageWrapper
                          id={row.id}
                          imgClass={classes.previewImageFloat}
                          alt={row.itemType}
                          imgUrl={row.imgUrlThumb}
                          handleClick={() => {
                            handlePreview(row.itemNumber);
                            setCurrentItemAdded(null);
                          }}
                        />
                        {row.includeBeacon && (
                          <BeaconIcon
                            tw="absolute -right-1 -bottom-1"
                            title="Item has a beacon"
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="left">{row.itemNumber}</TableCell>
                    {row.brand.length > 1 ? (
                      <Tooltip
                        placement="left"
                        title={`${row.brand.join(", ")}`}
                      >
                        <TableCell align="left">
                          {row.brand[0]}
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">{row.brand[0]}</TableCell>
                    )}
                    {row.programs.length > 1 ? (
                      <Tooltip
                        placement="left"
                        title={`${row.programs.map((p) => p.name).join(", ")}`}
                      >
                        <TableCell align="left">
                          {row.programs.find(
                            (p) => p.id === String(row.mostRecentProgramId)
                          )?.name ?? "No active programs"}
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">{row.program[0]}</TableCell>
                    )}
                    <TableCell align="left">{row.itemType}</TableCell>
                    <TableCell align="left">{row.itemDescription}</TableCell>
                    <TableCell align="left">{row.packSize}</TableCell>
                    {type === "inventory" && (
                      <TableCell align="left">
                        {!row.warehouse
                          ? "Currently Unavailable"
                          : row.stock <= 0
                          ? "Out of Stock"
                          : row.stock}
                      </TableCell>
                    )}
                    {type === "inventory" && (
                      <TableCell align="left">
                        <VariantAllocationTooltip variants={row.variants}>
                          {handleTerritoryQtys(row.variants)}
                        </VariantAllocationTooltip>
                      </TableCell>
                    )}
                    <TableCell>{`${formatMoney(
                      row.estCost,
                      false
                    )}`}</TableCell>
                  </TableRow>
                );
              })}
            {isItemsLoading && (
              <TableRow>
                <TableCell align="left" colSpan={type === "inventory" ? 11 : 9}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

OrderVariantTableView.propTypes = {
  type: PropTypes.string.isRequired,
  currentItems: PropTypes.array.isRequired,
  handlePreview: PropTypes.func.isRequired,
  setCurrentItemAdded: PropTypes.func.isRequired,
};

export default React.memo(OrderVariantTableView);
