/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { TextField } from "@mui/material";

import format from "date-fns/format";
import subDays from "date-fns/subDays";
import _ from "lodash";
import FilterChipList from "src/components/Filtering/FilterChipList";
import SimpleReportGenerator from "src/components/Reporting/SimpleReportGenerator";
import AddressAutoComplete from "src/components/Utility/AutoCompleteFields/AddressAutoComplete";
import BUAutoComplete from "src/components/Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "src/components/Utility/AutoCompleteFields/BrandAutoComplete";
import ItemTypeAutoComplete from "src/components/Utility/AutoCompleteFields/ItemTypeAutoComplete";
import ProgramAutoComplete from "src/components/Utility/AutoCompleteFields/ProgramAutoComplete";
import StateAutoComplete from "src/components/Utility/AutoCompleteFields/StateAutoComplete";
import SupplierAutoComplete from "src/components/Utility/AutoCompleteFields/SupplierAutoComplete";
import TerritoryAutoComplete from "src/components/Utility/AutoCompleteFields/TerritoryAutoComplete";
import UserAutoComplete from "src/components/Utility/AutoCompleteFields/UserAutoComplete";
import AnaplanProgramTypeSelector from "src/components/Utility/Selectors/AnaplanProgramTypeSelector";
import DateRangeSelector from "src/components/Utility/Selectors/DateRangeSelector";
import OrderTypeSelector from "src/components/Utility/Selectors/OrderTypeSelector";
import { setChips, updateSingleFilter } from "src/redux/slices/filterSlice";

import { getCouponProductionReport } from "../redux/slices/reports/reportSlice";

const freeTypeFields = {
  itemNumber: "Sequence #",
  orderNumber: "Order #",
  anaplanCode: "Anaplan Code",
  // budgetId: "Budget ID",
};

const ReportCouponProduction = () => {
  const dispatch = useDispatch();
  const [reset, setReset] = useState(false);

  const {
    handleSubmit,
    register,
    reset: freeTypeReset,
  } = useForm({
    defaultValues: _(freeTypeFields)
      .mapValues(() => "")
      .value(),
  });

  const filterObject = useSelector((state) => state.filters);

  const handleFilters = (value, name) => {
    if (!name) return;
    dispatch(updateSingleFilter({ filter: name, value: value }));
    dispatch(setChips({ filterType: "history" }));
  };

  const handleReset = () => {
    freeTypeReset();
    handleFilters(format(subDays(new Date(), 7), "MM/dd/yyyy"), "fromDate");
    handleFilters(format(new Date(), "MM/dd/yyyy"), "toDate");
    setReset(true);
  };

  const handleGetReport = (freeTypeFields) => {
    return new Promise((resolve) => {
      dispatch(getCouponProductionReport(freeTypeFields, resolve));
    });
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SimpleReportGenerator
        reportTitle={"Coupon Production Report"}
        fileName={"coupon_production_report.csv"}
        reportResetFunc={handleReset}
        reportGetFunc={handleSubmit(handleGetReport)}
      >
        <div tw="space-y-8">
          <div tw="grid grid-cols-3 gap-4 mb-6">
            {Object.entries(freeTypeFields).map(([field, label]) => (
              <TextField
                key={field}
                size="small"
                label={label}
                {...register(field)}
                fullWidth
              />
            ))}
          </div>
          <div tw="grid grid-cols-3 gap-4">
            <DateRangeSelector
              classes={{}}
              fromLabel={"Submitted From Date"}
              toLabel={"Submitted To Date"}
              fromDate={new Date(filterObject.fromDate)}
              toDate={new Date(filterObject.toDate)}
              onChangeFromDate={(value) =>
                handleFilters(
                  format(value, "MM/dd/yyyy"),
                  "fromDate",
                  "history"
                )
              }
              onChangeToDate={(value) =>
                handleFilters(format(value, "MM/dd/yyyy"), "toDate", "history")
              }
            />

            <AnaplanProgramTypeSelector
              handleAnaplanProgramType={handleFilters}
              classes={{}}
              filterType={"history"}
            />

            <OrderTypeSelector
              classes={{}}
              handleOrderType={handleFilters}
              setType={handleFilters}
              orderType={filterObject.type}
              filterType={"history"}
            />

            <UserAutoComplete
              classes={{}}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
            />
            <BrandAutoComplete
              classes={{}}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
            />

            <AddressAutoComplete
              classes={{}}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
            />

            <ProgramAutoComplete
              classes={{}}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
            />

            <ItemTypeAutoComplete
              classes={{}}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
              couponOnly
            />

            <BUAutoComplete
              classes={{}}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
            />

            <StateAutoComplete
              classes={{}}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
            />

            <TerritoryAutoComplete
              classes={{}}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
              type={"inclusive"}
            />

            <SupplierAutoComplete
              classes={{}}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
            />
          </div>
          <FilterChipList />
        </div>
      </SimpleReportGenerator>
    </>
  );
};

export default ReportCouponProduction;
