/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete } from "@mui/material";

import { DefaultTextField } from "src/components/Forms/DefaultInputs";
import ReduxReportGenerator from "src/components/Reporting/ReduxReportGenerator";
import { fetchAllOcms } from "src/redux/slices/orderCalendarMonthsSlice";
import { getPreOrderNonParticipantReport } from "src/redux/slices/reports/reportSlice";

import { getPastOrderWindowOptions } from "@features/reports/helpers";
import { OrderCalendarMonth } from "@models/OrderCalendarMonth";
import { Program } from "@models/Program";
import { ResourceAutocomplete } from "@utils/forms";

const ReportPreOrderNonParticipant = () => {
  const [selectedOCM, setSelectedOCM] = useState<OrderCalendarMonth | null>(
    null
  );
  const [programs, setPrograms] = useState<Program[]>([]);
  const allOcms = useSelector((state: any) => state.orderCalendarMonths.all);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllOcms());
  }, [dispatch]);

  const handleReset = () => {
    setSelectedOCM(null);
    setPrograms([]);
  };

  const handleGetReport = () => {
    const params = {
      filter: {
        "order-calendar-month-id": selectedOCM?.id,
        "program-ids": programs.map((p) => p.id).join(","),
      },
    };
    dispatch(getPreOrderNonParticipantReport(params));
  };

  const isFormValid = selectedOCM !== null && programs.length > 0;

  return (
    <ReduxReportGenerator
      reportTitle="Pre Order Non-Participant Report"
      fileName="pre-order-non-participant-report.csv"
      reportGetFunc={handleGetReport}
      reportResetFunc={handleReset}
      isGenerateDisabled={!isFormValid}
    >
      <div tw="w-[400px] space-y-6 mx-auto">
        <Autocomplete
          options={getPastOrderWindowOptions(allOcms)}
          getOptionLabel={(option: OrderCalendarMonth) =>
            option.orderWindowName
          }
          value={selectedOCM}
          onChange={(_, newValue) => setSelectedOCM(newValue)}
          renderInput={(params) => (
            <DefaultTextField
              {...params}
              label="OCM"
              fullWidth
              size="small"
              required
            />
          )}
        />

        <ResourceAutocomplete
          label="Program"
          resource="programs"
          requestParams={{
            filter: {
              isActive: true,
              isAwaitingCode: false,
            },
          }}
          value={programs}
          onChange={(_, value) => setPrograms(value)}
          multiple
          required
        />
      </div>
    </ReduxReportGenerator>
  );
};

export default ReportPreOrderNonParticipant;
