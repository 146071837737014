import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import _ from "lodash";
import PropTypes from "prop-types";

import { useItemTypesQuery } from "@features/itemTypes";

const ItemTypeAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  filterType,
  couponOnly = false,
}) => {
  const [open, setOpen] = useState(false);
  const [itemType, setItemType] = useState("");
  const [currentItemTypes, setCurrentItemTypes] = useState([]);

  const currentFiltersItemType = useSelector((state) => state.filters.itemType);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);
  let { data = [] } = useItemTypesQuery();

  if (couponOnly) {
    data = data.filter((itemType) => itemType.categoryCode === "COU");
  }

  const itemTypeOptions = _.sortBy(data, "description").map((itemType) => ({
    label: [itemType.externalId, itemType.description].join(" - "),
    id: itemType.id,
  }));

  useEffect(() => {
    if (currentFiltersItemType.length !== currentItemTypes.length) {
      setCurrentItemTypes(currentFiltersItemType);
    }
  }, [currentFiltersItemType, currentItemTypes.length]);

  useEffect(() => {
    if (reset) {
      setItemType("");
      setCurrentItemTypes([]);
      setReset(false);
    }
  }, [reset, setItemType, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        className={classes.queryField}
        classes={{
          popper: classes.liftedPopper,
        }}
        id="itemType-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={itemType}
        onInputChange={(_evt, value) => setItemType(value)}
        onChange={(evt, value) => {
          handleChange(value, "itemType", filterType);
          setCurrentItemTypes(value);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={itemTypeOptions}
        value={currentItemTypes}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Item Type"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "new-password",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </>
  );
};

ItemTypeAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default ItemTypeAutoComplete;
