import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import addDays from "date-fns/addDays";
import _ from "lodash";
import PropTypes from "prop-types";
import { axiosGet } from "src/api/axiosCalls";
import { mapPrograms } from "src/redux/slices/programs/maps";

import { SUPPLIER_ROLES } from "../../constants/permissions";
import {
  setClear,
  setSorted,
  updateMultipleFilters,
} from "../../redux/slices/filterSlice";
import { TextInput } from "../Forms/ControlledInputs";
import AddressAutoComplete from "../Utility/AutoCompleteFields/AddressAutoComplete";
import BUAutoComplete from "../Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "../Utility/AutoCompleteFields/BrandAutoComplete";
import ItemTypeAutoComplete from "../Utility/AutoCompleteFields/ItemTypeAutoComplete";
import ProgramAutoComplete from "../Utility/AutoCompleteFields/ProgramAutoComplete";
import PurchaserAutoComplete from "../Utility/AutoCompleteFields/PurchaserAutoComplete";
import StateAutoComplete from "../Utility/AutoCompleteFields/StateAutoComplete";
import SupplierAutoComplete from "../Utility/AutoCompleteFields/SupplierAutoComplete";
import TerritoryAutoComplete from "../Utility/AutoCompleteFields/TerritoryAutoComplete";
import UserAutoComplete from "../Utility/AutoCompleteFields/UserAutoComplete";
import AnaplanProgramTypeSelector from "../Utility/Selectors/AnaplanProgramTypeSelector";
import DateRangeSelector from "../Utility/Selectors/DateRangeSelector";
import OrderTypeSelector from "../Utility/Selectors/OrderTypeSelector";
import StatusSelector from "../Utility/Selectors/StatusSelector";
import { executeOnEnter } from "./utility";

const enabledStatusArray = [
  "submitted",
  "not-draft",
  "approved",
  "order-canceled",
  "compliance-canceled",
  "destroyed",
];

const FiltersHistory = ({
  reset,
  setReset,
  handleFilters,
  classes,
  historyType,
}) => {
  const {
    control,
    handleSubmit,
    reset: resetForm,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      itemNumber: "",
      orderNum: "",
      rfpNum: "",
      poNum: "",
      poInvoiceNumber: "",
      poExternalSapId: "",
      anaplanOrRtaProgramId: "",
      budgetId: "",
    },
  });

  const reg = (name) => ({ control, name });

  const dispatch = useDispatch();
  const [excludeSupplier, setExcludeSupplier] = useState(false);
  const [status, setStatus] = useState(
    historyType === "rollup"
      ? "submitted"
      : historyType === "orders" || historyType === "orders"
      ? "not-draft"
      : "all"
  );

  const currentUserRole = useSelector((state) => state.user.role);
  const isSupplier = SUPPLIER_ROLES.includes(currentUserRole);
  const filters = useSelector((state) => state.filters);
  const { groupBy } = filters;
  const toDate = useSelector((state) => state.filters.toDate);
  const fromDate = useSelector((state) => state.filters.fromDate);
  const isLoading = useSelector((state) => state.globalLoad.isLoading);
  const [type, setType] = useState("all");
  const handleFreeTypeSearch = async ({ anaplanOrRtaProgramId, ...data }) => {
    if (anaplanOrRtaProgramId) {
      const res = await axiosGet(
        `/api/programs?filter[program-id-or-anaplan-code]=${anaplanOrRtaProgramId}`
      );
      if (res.data.length === 0) {
        alert("No Programs match this ID");
      } else {
        handleFilters(mapPrograms(res.data), "program", "item-all");
        setValue("anaplanOrRtaProgramId", "");
      }
    }
    dispatch(
      updateMultipleFilters({
        filterObject: {
          ..._.mapValues(data, (v) => v || null),
          preventFetch: false,
        },
      })
    );
    // setSorted triggers the useEffect in FilterDrawer to
    // fetch the data _after_ the filters are updated
    dispatch(setSorted());
  };

  // Reset form to filter values when filters change
  useEffect(() => {
    const values = getValues();
    resetForm(
      _(filters)
        .pick(Object.keys(values))
        .mapValues((v) => v || "")
        .value()
    );
  }, [filters, resetForm, getValues]);

  useEffect(() => {
    if (reset) resetForm();
  }, [reset, resetForm]);

  return (
    <>
      <List
        component="form"
        onSubmit={handleSubmit(handleFreeTypeSearch)}
        onKeyDown={executeOnEnter(handleSubmit(handleFreeTypeSearch))}
      >
        {historyType !== "rfp" && historyType !== "po" && (
          <>
            <ListItem
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography className={classes.headerText}>Group By:</Typography>
              <br />
              <ButtonGroup
                orientation="vertical"
                fullWidth
                color="secondary"
                aria-label="order-group"
              >
                <Button
                  className={
                    groupBy === "item"
                      ? classes.largeButton
                      : classes.selectedButton
                  }
                  variant={groupBy === "item" ? "contained" : "outlined"}
                  onClick={() => {
                    handleFilters("item", "groupBy", "history");
                  }}
                  disabled={isLoading}
                >
                  ITEM
                </Button>
                <Button
                  className={
                    groupBy === "order"
                      ? classes.largeButton
                      : classes.selectedButton
                  }
                  variant={groupBy === "order" ? "contained" : "outlined"}
                  onClick={() => {
                    handleFilters("order", "groupBy", "history");
                  }}
                  disabled={isLoading}
                >
                  ORDER
                </Button>
              </ButtonGroup>
            </ListItem>
            <ListItem />
            <Divider />
            <ListItem />
          </>
        )}

        <ListItem>
          <TextInput
            fullWidth
            label="Sequence #"
            {...reg("itemNumber")}
            disabled={isLoading}
          />
        </ListItem>
        {historyType !== "rfp" && historyType !== "po" && (
          <ListItem>
            <TextInput
              fullWidth
              label="Order #"
              {...reg("orderNum")}
              disabled={isLoading}
            />
          </ListItem>
        )}
        {historyType === "rfp" && (
          <ListItem>
            <TextInput
              fullWidth
              label="RFP #"
              {...reg("rfpNum")}
              disabled={isLoading}
            />
          </ListItem>
        )}
        {((historyType === "orders" && groupBy === "item") ||
          historyType === "po") && (
          <ListItem>
            <TextInput
              fullWidth
              label="PO #"
              {...reg("poNum")}
              disabled={isLoading}
            />
          </ListItem>
        )}
        {historyType === "po" && (
          <>
            <ListItem>
              <TextInput
                fullWidth
                {...reg("poInvoiceNumber")}
                label="PO Invoice #"
                disabled={isLoading}
              />
            </ListItem>
            <ListItem>
              <TextInput
                fullWidth
                {...reg("poExternalSapId")}
                label="SAP PO #"
                disabled={isLoading}
              />
            </ListItem>
            <ListItem>
              <TextInput
                fullWidth
                {...reg("supplierReference")}
                label="Supplier Ref #"
                disabled={isLoading}
              />
            </ListItem>
          </>
        )}
        {historyType === "orders" && (
          <>
            <ListItem>
              <TextInput
                fullWidth
                {...reg("anaplanCode")}
                label="Anaplan Codes"
                disabled={isLoading}
              />
            </ListItem>
            <ListItem>
              <TextInput
                fullWidth
                {...reg("budgetId")}
                label="Budget ID"
                disabled={isLoading}
              />
            </ListItem>
          </>
        )}
        {historyType !== "po" && historyType !== "rfp" && (
          <ListItem>
            <TextInput
              fullWidth
              label="Program ID"
              {...reg("anaplanOrRtaProgramId")}
              disabled={isLoading}
            />
          </ListItem>
        )}
        <ListItem>
          <Button
            role="search"
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleSubmit(handleFreeTypeSearch)}
            disabled={isLoading}
          >
            SEARCH
          </Button>
        </ListItem>
        <ListItem />
        <Divider />
        <ListItem />
        {(!["rfp", "po", "rollup"].includes(historyType) ||
          (historyType === "po" && status === "complete")) && (
          <>
            <DateRangeSelector
              classes={classes}
              fromLabel={
                historyType === "po"
                  ? "Completed From Date"
                  : "Submitted From Date"
              }
              toLabel={
                historyType === "po" ? "Completed To Date" : "Submitted To Date"
              }
              fromDate={fromDate ? new Date(fromDate) : new Date("02/01/2021")}
              toDate={toDate ? new Date(toDate) : addDays(new Date(), 1)}
              onChangeFromDate={(value) =>
                handleFilters(value, "fromDate", "history")
              }
              onChangeToDate={(value) =>
                handleFilters(value, "toDate", "history")
              }
              disabled={
                isLoading ||
                (!enabledStatusArray.includes(status) &&
                  historyType !== "approvals" &&
                  historyType !== "po")
              }
              wrapInListItem={true}
            />
          </>
        )}
        {historyType === "orders" && (
          <ListItem>
            <AnaplanProgramTypeSelector
              handleAnaplanProgramType={handleFilters}
              classes={classes}
              filterType={"history"}
            />
          </ListItem>
        )}
        {!["rfp", "approvals", "rollup"].includes(historyType) && (
          <ListItem>
            <OrderTypeSelector
              classes={classes}
              handleOrderType={handleFilters}
              setType={setType}
              orderType={type}
              filterType={
                historyType === "rollup"
                  ? "rollup"
                  : historyType === "po"
                  ? "po"
                  : "history"
              }
            />
          </ListItem>
        )}
        {(historyType === "rollup" ||
          historyType === "rfp" ||
          historyType === "po" ||
          historyType === "orders") && (
          <ListItem>
            <StatusSelector
              handleStatus={handleFilters}
              status={status}
              setStatus={setStatus}
              classes={classes}
              filterType={
                historyType === "rollup"
                  ? "history"
                  : isSupplier
                  ? `${historyType}Supplier`
                  : historyType
              }
            />
          </ListItem>
        )}
        {historyType !== "rfp" &&
          historyType !== "po" &&
          currentUserRole !== "compliance" && (
            <ListItem>
              <UserAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"history"}
              />
            </ListItem>
          )}
        <ListItem>
          <BrandAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"history"}
          />
        </ListItem>
        {historyType !== "rollup" &&
          historyType !== "rfp" &&
          historyType !== "po" && (
            <ListItem>
              <AddressAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"history"}
              />
            </ListItem>
          )}
        <ListItem>
          <ProgramAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"history"}
          />
        </ListItem>
        <ListItem>
          <ItemTypeAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"history"}
          />
        </ListItem>
        {historyType !== "rfp" &&
          historyType !== "po" &&
          historyType !== "rollup" && (
            <ListItem>
              <BUAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"history"}
              />
            </ListItem>
          )}
        {!["po", "rfp"].includes(historyType) && (
          <ListItem>
            <StateAutoComplete
              classes={classes}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
            />
          </ListItem>
        )}
        {!["po", "rfp"].includes(historyType) && (
          <ListItem>
            <TerritoryAutoComplete
              classes={classes}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"history"}
              type={"inclusive"}
            />
          </ListItem>
        )}
        {["po", "rollup", "approvals", "orders"].includes(historyType) &&
          !isSupplier && (
            <>
              <ListItem>
                <SupplierAutoComplete
                  classes={classes}
                  handleChange={handleFilters}
                  reset={reset}
                  setReset={setReset}
                  filterType={"history"}
                />
              </ListItem>
              {!["orders", "rollup"].includes(historyType) && (
                <ListItem>
                  <PurchaserAutoComplete
                    classes={classes}
                    handleChange={handleFilters}
                    reset={reset}
                    setReset={setReset}
                    filterType={"history"}
                  />
                </ListItem>
              )}
            </>
          )}
        {historyType === "po" && currentUserRole === "purchaser" && (
          <ListItem
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography className={classes.headerText}>
              Exclude Select:
            </Typography>
            <br />
            <ButtonGroup
              orientation="vertical"
              fullWidth
              color="secondary"
              aria-label="exclude-select"
            >
              <Button
                className={
                  excludeSupplier ? classes.largeButton : classes.selectedButton
                }
                variant={excludeSupplier ? "contained" : "outlined"}
                onClick={() => {
                  setExcludeSupplier(true);
                  handleFilters("22", "excludedSupplier", "itemRollup");
                }}
                disabled={isLoading}
              >
                YES
              </Button>
              <Button
                className={
                  !excludeSupplier
                    ? classes.largeButton
                    : classes.selectedButton
                }
                variant={!excludeSupplier ? "contained" : "outlined"}
                onClick={() => {
                  setExcludeSupplier(false);
                  handleFilters(null, "excludedSupplier", "itemRollup");
                }}
                disabled={isLoading}
              >
                NO
              </Button>
            </ButtonGroup>
          </ListItem>
        )}
        <Divider />
        <ListItem />
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(setClear());
            }}
            disabled={isLoading}
          >
            CLEAR FILTERS
          </Button>
        </ListItem>
      </List>
    </>
  );
};

FiltersHistory.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default FiltersHistory;
