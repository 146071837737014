import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";
import { BudgetFilters } from "src/features/budgets";

import {
  useDetailedInput,
  useDetailedNumberOnlyInput,
} from "../../hooks/InputHooks";
import { fetchC2MItemHistory } from "../../redux/slices/c2mData/c2mItemHistorySlice";
import {
  fetchC2MOrderHistory,
  resetHistoryReport,
  setTriggerCSVFalse as setTriggerC2MCSVFalse,
} from "../../redux/slices/c2mData/c2mOrderHistorySlice";
import {
  clearCompItemReport,
  fetchTriggeredRules,
  setTriggerCSVFalse as setTriggeredRuleCSVFalse,
} from "../../redux/slices/compliance/complianceItemsSlice";
import {
  clearRulesReport,
  fetchRules,
  setTriggerCSVFalse as setRulesCSVFalse,
} from "../../redux/slices/compliance/complianceRulesSlice";
import {
  resetFilters,
  setChips,
  setClearFreeType,
  setFetchCurrent,
  setSorted,
  updateMultipleFilters,
  updateSingleFilter,
} from "../../redux/slices/filterSlice";
import { clearBrands } from "../../redux/slices/items/brandSlice";
import {
  clearItemReport,
  clearItemSelection,
  fetchFilteredItems,
  resetItems,
  setTriggerCSVFalse as setItemCSVFalse,
} from "../../redux/slices/items/itemSlice";
import {
  clearOrderHistoryReports,
  fetchOrderHistory,
  fetchOrderVariantHistory,
  setTriggerCSVFalse as setOrderHistoryCSVFalse,
} from "../../redux/slices/ordering/orderHistorySlice";
import {
  clearOrderSetReports,
  fetchFilteredOrderSetVariants,
  fetchFilteredOrderSets,
  resetOrderSetHistory,
  setTriggerCSVFalse,
} from "../../redux/slices/ordering/orderSetHistorySlice";
import { fetchFilteredAnaplanPrograms } from "../../redux/slices/planningTool/anaplanProgramSlice";
import { fetchFilteredPrograms } from "../../redux/slices/planningTool/programSlice";
import {
  fetchQuotes,
  fetchRFQs,
} from "../../redux/slices/planningTool/rfqSlice";
import {
  clearPOReport,
  fetchFilteredPOHistory,
  setTriggerCSVFalse as setPOTriggerCSVFalse,
} from "../../redux/slices/purchasing/purchaseOrderHistorySlice";
import {
  clearPORollupReport,
  fetchPOItemRollups,
  setItemRollupIdSelection,
  setTriggerCSVFalse as setPORollupCSVFalse,
} from "../../redux/slices/purchasing/purchaseOrderSlice";
import { fetchFilteredRFPHistory } from "../../redux/slices/purchasing/rfpHistorySlice";
import {
  clearRFPRollupReport,
  fetchRFPRollupItems,
  setTriggerCSVFalse as setRFPRollupCSVFalse,
  setSelectedRFPItem,
} from "../../redux/slices/purchasing/rfpSlice";
import { fetchFilteredUsers } from "../../redux/slices/users/userUpdateSlice";
import FiltersPlanningToolActivate from "./FilterPlanningToolActivate";
import FiltersC2MHistory from "./FiltersC2MHistory";
import FiltersC2MItemHistory from "./FiltersC2MItemHistory";
import FiltersCompliance from "./FiltersCompliance";
import FiltersHistory from "./FiltersHistory";
import FiltersItemRollup from "./FiltersItemRollup";
import FiltersItems from "./FiltersItems";
import FiltersOnDemandItems from "./FiltersOnDemandItems";
import FiltersPlanningTool from "./FiltersPlanningTool";
import FiltersPrograms from "./FiltersPrograms";
import FiltersQuotes from "./FiltersQuotes";
import FiltersRFQs from "./FiltersRFQs";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  drawer: {
    width: "300px",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "300px",
    zIndex: "1200",
  },
  selectedButton: {
    fontWeight: "600",
    fontSize: "1rem",
    textAlign: "center",
    color: "#737373",
  },
}));

const FilterDrawer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const filterType = useSelector((state) => state.filters.filterType);
  const setToClear = useSelector((state) => state.filters.clearFilters);
  const sorted = useSelector((state) => state.filters.sorted);
  const fetchCurrent = useSelector((state) => state.filters.fetchCurrent);
  const defaultFilters = useSelector((state) => state.filters.defaultFilters);
  const allFilters = useSelector((state) => state.filters);
  const clearFreeType = useSelector((state) => state.filters.clearFreeType);
  const open = useSelector((state) => state.filters.filtersOpen);
  const currentTerritoryId = useSelector(
    (state) => state.user.currentTerritory
  );

  const [reset, setReset] = useState(false);

  const validFilters = [
    "itemNumber",
    "rfpNum",
    "poNum",
    "orderNum",
    "tag",
    "ruleType",
    "status",
    "bu",
    "channel",
    "itemType",
    "month",
    "sortProgramsBy",
    "groupBy",
    "program",
    "brand",
    "user",
    "purchaser",
    "address",
    "orderType",
    "territory",
    "exclusiveTerritory",
    "supplier",
    "favItems",
    "itemDesc",
    "itemCommentAndSubType",
    "stateIds",
    "type",
    "isAccolade",
    "itemOrderType",
    "excludedSupplier",
    "poInvoiceNumber",
    "poExternalSapId",
    "supplierReference",
    "c2mTerritory",
    "c2mProgramName",
    "c2mBrands",
    "c2mBusinessUnit",
    "c2mDistributor",
    "c2mName",
    "c2mProgramTheme",
    "c2mItemType",
    "supplierQuoteId",
    "programName",
    "workfrontId",
    "programId",
    "couponBarcodeId",
    "programType",
    "inMarketYear",
    "inMarketMonthYears",
    "orderWindowMonthYear",
    "codeOrName",
    "ocmIds",
    "anaplanCode",
    "budgetId",
    "anaplanProgramType",
  ];

  const freeTypeFields = [
    "poNum",
    "rfpNum",
    "itemNumber",
    "itemDesc",
    "itemCommentAndSubType",
    "orderNum",
    "poInvoiceNumber",
    "poExternalSapId",
    "supplierReference",
    "c2mTerritory",
    "c2mProgramName",
    "c2mBrands",
    "c2mBusinessUnit",
    "c2mDistributor",
    "c2mName",
    "c2mProgramTheme",
    "c2mItemType",
    "supplierQuoteId",
    "programName",
    "codeOrName",
    "workfrontId",
    "programId",
    "couponBarcodeId",
    "anaplanCode",
    "budgetId",
  ];

  const dateFilterFields = [
    "fromDate",
    "toDate",
    "c2mOrderDateFrom",
    "c2mOrderDateTo",
    "c2mInMarketDateFrom",
    "c2mInMarketDateTo",
  ];

  const handleFilters = useCallback(
    (value, filter, type) => {
      let currentFilters = { ...allFilters };
      if (validFilters.includes(filter)) {
        let trimmedValue = freeTypeFields.includes(filter)
          ? value.trim()
          : value;
        dispatch(updateSingleFilter({ filter: filter, value: trimmedValue }));
        if (filter === "status" && trimmedValue === "shipping-hold") {
          dispatch(updateSingleFilter({ filter: "hasShipHold", value: true }));
          currentFilters.hasShipHold = true;
        }
        if (filter === "status" && trimmedValue !== "shipping-hold") {
          dispatch(updateSingleFilter({ filter: "hasShipHold", value: false }));
          currentFilters.hasShipHold = false;
        }
        currentFilters[filter] = trimmedValue;
        if (!freeTypeFields.includes(filter)) {
          dispatch(setChips({ filterType: type }));
        }
      } else if (dateFilterFields.includes(filter)) {
        dispatch(
          updateSingleFilter({
            filter: filter,
            value: format(value, "MM/dd/yyyy"),
          })
        );
        currentFilters[filter] = format(value, "MM/dd/yyyy");
      } else if (filter === "c2mOrderDateRange") {
        dispatch(
          updateMultipleFilters({
            filterObject: {
              c2mOrderDateFrom: value.from,
              c2mOrderDateTo: value.to,
            },
          })
        );
        currentFilters.c2mOrderDateFrom = value.from;
        currentFilters.c2mOrderDateTo = value.to;
      } else if (filter === "c2mInMarketDateRange") {
        dispatch(
          updateMultipleFilters({
            filterObject: {
              c2mInMarketDateFrom: value.from,
              c2mInMarketDateTo: value.to,
            },
          })
        );
        currentFilters.c2mInMarketDateFrom = value.from;
        currentFilters.c2mInMarketDateTo = value.to;
      }
      if (filterType === "history-orders" && !freeTypeFields.includes(filter)) {
        if (currentFilters.groupBy === "order") {
          dispatch(fetchOrderHistory(currentFilters));
        } else {
          dispatch(fetchOrderVariantHistory(currentFilters));
        }
        dispatch(setOrderHistoryCSVFalse());
        dispatch(clearOrderHistoryReports());
      }
      if (
        (filterType === "history-rollup" ||
          filterType === "history-approvals") &&
        !freeTypeFields.includes(filter)
      ) {
        if (
          filterType === "history-approvals" ||
          filter !== "groupBy" ||
          !currentFilters.preventFetch
        ) {
          dispatch(
            updateSingleFilter({ filter: "preventFetch", value: false })
          );
          if (filterType !== "history-rollup") {
            if (currentFilters.groupBy === "order") {
              dispatch(fetchFilteredOrderSets(currentFilters));
            } else {
              dispatch(fetchFilteredOrderSetVariants(currentFilters));
            }
          }
        }
        dispatch(setTriggerCSVFalse());
        dispatch(clearOrderSetReports());
      }
      if (filterType === "itemRollup-rfp" && !freeTypeFields.includes(filter)) {
        dispatch(fetchRFPRollupItems(currentFilters));
        dispatch(setSelectedRFPItem({ itemId: null }));
        dispatch(setRFPRollupCSVFalse());
        dispatch(clearRFPRollupReport());
      }
      if (filterType === "itemRollup-po" && !freeTypeFields.includes(filter)) {
        dispatch(fetchPOItemRollups(currentFilters));
        dispatch(setItemRollupIdSelection({ selectedItems: [] }));
        dispatch(setPORollupCSVFalse());
        dispatch(clearPORollupReport());
      }
      if (filterType === "history-rfp" && !freeTypeFields.includes(filter)) {
        dispatch(fetchFilteredRFPHistory(currentFilters));
      }
      if (filterType === "history-po" && !freeTypeFields.includes(filter)) {
        dispatch(fetchFilteredPOHistory(currentFilters));
        dispatch(setPOTriggerCSVFalse());
        dispatch(clearPOReport());
      }
      if (
        (filterType === "item-inventory" ||
          filterType === "item-inventory" ||
          filterType === "item-onDemand" ||
          filterType === "item-all" ||
          filterType === "item-archive") &&
        !freeTypeFields.includes(filter)
      ) {
        dispatch(clearItemSelection());
        dispatch(fetchFilteredItems(currentFilters));
        if (filterType === "item-inventory") {
          dispatch(clearItemReport());
          dispatch(setItemCSVFalse());
        }
      }
      if (filterType === "compliance-rules") {
        dispatch(fetchRules(currentFilters));
        dispatch(setRulesCSVFalse());
        dispatch(clearRulesReport());
      }
      if (
        filterType === "compliance-items" &&
        !freeTypeFields.includes(filter)
      ) {
        dispatch(fetchTriggeredRules(currentFilters));
        dispatch(setTriggeredRuleCSVFalse());
        dispatch(clearCompItemReport());
      }
      if (
        filterType === "c2m-order-history" &&
        !freeTypeFields.includes(filter)
      ) {
        dispatch(fetchC2MOrderHistory(currentFilters));
        dispatch(setTriggerC2MCSVFalse());
        dispatch(resetHistoryReport());
      }
      if (filterType === "planningTool" && !freeTypeFields.includes(filter)) {
        dispatch(fetchFilteredPrograms(currentFilters));
      }
      if (
        filterType === "planningToolActivate" &&
        !freeTypeFields.includes(filter)
      ) {
        dispatch(fetchFilteredAnaplanPrograms(currentFilters));
      }
      if (filterType === "RFQ" && !freeTypeFields.includes(filter)) {
        dispatch(fetchRFQs(currentFilters));
      }
      if (filterType === "quote" && !freeTypeFields.includes(filter)) {
        dispatch(fetchQuotes(currentFilters));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFilters, filterType, dispatch]
  );

  const {
    value: itemNumber,
    bind: bindSequenceNum,
    reset: resetSequenceNum,
  } = useDetailedInput("", handleFilters, "itemNumber", filterType);
  const {
    value: poNum,
    bind: bindPoNum,
    reset: resetPoNum,
  } = useDetailedNumberOnlyInput("", handleFilters, "poNum", filterType);
  const {
    value: rfpNum,
    bind: bindrfpNum,
    reset: resetrfpNum,
  } = useDetailedNumberOnlyInput("", handleFilters, "rfpNum", filterType);
  const {
    value: itemDesc,
    bind: bindItemDesc,
    reset: resetItemDesc,
  } = useDetailedInput("", handleFilters, "itemDesc", filterType);
  const { reset: resetItemCommentAndSubType } = useDetailedInput(
    "",
    handleFilters,
    "itemCommentAndSubType",
    filterType
  );
  const {
    value: orderNum,
    bind: bindOrderNum,
    reset: resetOrderNum,
  } = useDetailedNumberOnlyInput("", handleFilters, "orderNum", filterType);
  const {
    value: poInvoiceNumber,
    bind: bindPOInvoiceNumber,
    reset: resetPOInvoiceNumber,
  } = useDetailedInput("", handleFilters, "poInvoiceNumber", filterType);
  const {
    value: poExternalSapId,
    bind: bindPOExternalSapId,
    reset: resetPOExternalSapId,
  } = useDetailedNumberOnlyInput(
    "",
    handleFilters,
    "poExternalSapId",
    filterType
  );
  const {
    value: supplierReference,
    bind: bindSupplierReference,
    reset: resetSupplierReference,
  } = useDetailedInput("", handleFilters, "supplierReference", filterType);
  const {
    value: c2mTerritory,
    bind: bindC2mTerritory,
    reset: resetC2mTerritory,
  } = useDetailedInput("", handleFilters, "c2mTerritory", filterType);
  const {
    value: c2mProgramName,
    bind: bindC2mProgramName,
    reset: resetC2mProgramName,
  } = useDetailedInput("", handleFilters, "c2mProgramName", filterType);
  const {
    value: c2mBrands,
    bind: bindC2mBrands,
    reset: resetC2mBrands,
  } = useDetailedInput("", handleFilters, "c2mBrands", filterType);
  const {
    value: c2mBusinessUnit,
    bind: bindC2mBusinessUnit,
    reset: resetC2mBusinessUnit,
  } = useDetailedInput("", handleFilters, "c2mBusinessUnit", filterType);
  const {
    value: c2mDistributor,
    bind: bindC2mDistributor,
    reset: resetC2mDistributor,
  } = useDetailedInput("", handleFilters, "c2mDistributor", filterType);
  const {
    value: c2mName,
    bind: bindC2mName,
    reset: resetC2mName,
  } = useDetailedInput("", handleFilters, "c2mName", filterType);
  const {
    value: c2mItemType,
    bind: bindC2mItemType,
    reset: resetC2mItemType,
  } = useDetailedInput("", handleFilters, "c2mItemType", filterType);
  const {
    value: c2mProgramTheme,
    bind: bindC2mProgramTheme,
    reset: resetC2mProgramTheme,
  } = useDetailedInput("", handleFilters, "c2mProgramTheme", filterType);
  const {
    value: workfrontId,
    bind: bindWorkfrontId,
    reset: resetWorkfrontId,
  } = useDetailedInput("", handleFilters, "workfrontId", filterType);
  const {
    value: programId,
    bind: bindProgramId,
    reset: resetProgramId,
  } = useDetailedInput("", handleFilters, "programId", filterType);
  const {
    value: couponBarcodeId,
    bind: bindCouponBarcodeId,
    reset: resetCouponBarcodeId,
  } = useDetailedInput("", handleFilters, "couponBarcodeId", filterType);
  const {
    value: supplierQuoteId,
    bind: bindSupplierQuoteId,
    reset: resetSupplierQuoteId,
  } = useDetailedNumberOnlyInput(
    "",
    handleFilters,
    "supplierQuoteId",
    filterType
  );
  const {
    value: programName,
    bind: bindProgramName,
    reset: resetProgramName,
  } = useDetailedInput("", handleFilters, "programName", filterType);
  const {
    value: codeOrName,
    bind: bindCodeOrName,
    reset: resetCodeOrName,
  } = useDetailedInput("", handleFilters, "codeOrName", filterType);
  const {
    value: anaplanCode,
    bind: bindAnaplanCode,
    reset: resetAnaplanCode,
  } = useDetailedInput("", handleFilters, "anaplanCode", filterType);
  const { bind: bindBudgetId, reset: resetBudgetId } = useDetailedInput(
    "",
    handleFilters,
    "budgetId",
    filterType
  );

  const resetAllFilters = useCallback(() => {
    setReset(true);
    resetSequenceNum();
    resetPoNum();
    resetrfpNum();
    resetItemDesc();
    resetItemCommentAndSubType();
    resetOrderNum();
    resetSupplierReference();
    resetC2mTerritory();
    resetC2mProgramName();
    resetC2mBrands();
    resetC2mBusinessUnit();
    resetC2mDistributor();
    resetC2mItemType();
    resetC2mName();
    resetC2mProgramTheme();
    resetSupplierQuoteId();
    resetWorkfrontId();
    resetProgramId();
    resetCouponBarcodeId();
    resetProgramName();
    resetCodeOrName();
    resetAnaplanCode();
    resetBudgetId();
    dispatch(clearBrands());
    dispatch(resetFilters());
    if (defaultFilters) {
      let territoryFilters = {
        ...defaultFilters,
        currentTerritoryId: currentTerritoryId,
      };
      dispatch(updateMultipleFilters({ filterObject: territoryFilters }));
      if (filterType === "history-orders") {
        if (territoryFilters.groupBy === "order") {
          dispatch(fetchOrderHistory(territoryFilters));
        } else {
          dispatch(fetchOrderVariantHistory(territoryFilters));
        }
        dispatch(setOrderHistoryCSVFalse());
        dispatch(clearOrderHistoryReports());
      }
      if (
        filterType === "history-rollup" ||
        filterType === "history-approvals"
      ) {
        dispatch(resetOrderSetHistory());
        dispatch(setTriggerCSVFalse());
        dispatch(clearOrderSetReports());

        if (filterType === "history-approvals") {
          if (defaultFilters.groupBy === "order") {
            dispatch(fetchFilteredOrderSets(territoryFilters));
          } else {
            dispatch(fetchFilteredOrderSetVariants(territoryFilters));
          }
        } else {
          dispatch(updateSingleFilter({ filter: "preventFetch", value: true }));
        }
      }
      if (
        filterType === "item-inventory" ||
        filterType === "item-onDemand" ||
        filterType === "item-all" ||
        filterType === "item-archive"
      ) {
        dispatch(resetItems());
        dispatch(clearItemSelection());
        if (
          filterType !== "item-onDemand" ||
          territoryFilters.program.length > 0
        ) {
          dispatch(fetchFilteredItems(territoryFilters));
        }
        if (filterType === "item-inventory") {
          dispatch(clearItemReport());
          dispatch(setItemCSVFalse());
        }
      }
      if (filterType === "itemRollup-rfp") {
        dispatch(fetchRFPRollupItems(territoryFilters));
        dispatch(setSelectedRFPItem({ itemId: null }));
        dispatch(setRFPRollupCSVFalse());
        dispatch(clearRFPRollupReport());
      }
      if (filterType === "itemRollup-po") {
        dispatch(fetchPOItemRollups(territoryFilters));
        dispatch(setItemRollupIdSelection({ selectedItems: [] }));
        dispatch(setPORollupCSVFalse());
        dispatch(clearPORollupReport());
      }
      if (filterType === "history-rfp") {
        dispatch(fetchFilteredRFPHistory(territoryFilters));
      }
      if (filterType === "history-po") {
        dispatch(fetchFilteredPOHistory(territoryFilters));
        dispatch(setPOTriggerCSVFalse());
        dispatch(clearPOReport());
      }
      if (filterType === "compliance-rules") {
        dispatch(fetchRules(territoryFilters));
        dispatch(setRulesCSVFalse());
        dispatch(clearRulesReport());
      }
      if (filterType === "compliance-items") {
        dispatch(fetchTriggeredRules(territoryFilters));
        dispatch(setTriggeredRuleCSVFalse());
        dispatch(clearCompItemReport());
      }
      if (filterType === "user-settings") {
        dispatch(fetchFilteredUsers({}));
      }
      if (filterType === "c2m-order-history") {
        dispatch(fetchC2MOrderHistory(defaultFilters));
        dispatch(setTriggerC2MCSVFalse());
        dispatch(resetHistoryReport());
      }
      if (filterType === "c2m-item-history") {
        dispatch(fetchC2MItemHistory(defaultFilters));
      }
      if (filterType === "RFQ") {
        dispatch(fetchRFQs(defaultFilters));
      }
      if (filterType === "quotes") {
        dispatch(fetchQuotes(defaultFilters));
      }
      if (filterType === "planningToolActivate") {
        dispatch(fetchFilteredAnaplanPrograms(defaultFilters));
      }
      dispatch(setChips({ filterType: allFilters.filterType }));
    }
  }, [
    resetSequenceNum,
    resetPoNum,
    resetrfpNum,
    resetItemDesc,
    resetItemCommentAndSubType,
    resetOrderNum,
    resetSupplierReference,
    resetC2mTerritory,
    resetC2mProgramName,
    resetC2mBrands,
    resetC2mBusinessUnit,
    resetC2mDistributor,
    resetC2mItemType,
    resetC2mName,
    resetC2mProgramTheme,
    resetSupplierQuoteId,
    resetWorkfrontId,
    resetProgramId,
    resetCouponBarcodeId,
    resetProgramName,
    resetAnaplanCode,
    resetBudgetId,
    resetCodeOrName,
    dispatch,
    defaultFilters,
    currentTerritoryId,
    filterType,
    allFilters.filterType,
  ]);

  const handleOrderHistoryFetch = () => {
    dispatch(setChips({ filterType: "history" }));
    if (allFilters.groupBy === "order") {
      dispatch(fetchOrderHistory(allFilters));
    } else {
      dispatch(fetchOrderVariantHistory(allFilters));
    }
    dispatch(setOrderHistoryCSVFalse());
    dispatch(clearOrderHistoryReports());
  };

  const handleOrderSetFetch = () => {
    dispatch(setChips({ filterType: "history" }));
    dispatch(updateSingleFilter({ filter: "preventFetch", value: false }));

    if (filterType !== "history-rollup") {
      if (allFilters.groupBy === "order") {
        dispatch(fetchFilteredOrderSets(allFilters));
      } else {
        dispatch(fetchFilteredOrderSetVariants(allFilters));
      }
    }
    dispatch(setTriggerCSVFalse());
    dispatch(clearOrderSetReports());
  };

  const handleFilteredItemFetch = () => {
    dispatch(setChips({ filterType: "item-all" }));
    dispatch(fetchFilteredItems(allFilters));
    dispatch(clearItemSelection());
    dispatch(clearItemReport());
    dispatch(setItemCSVFalse());
  };

  const handleFilteredRFPFetch = () => {
    dispatch(setChips({ filterType: "history" }));
    dispatch(fetchFilteredRFPHistory(allFilters));
  };

  const handleRFPRollupFetch = () => {
    dispatch(setChips({ filterType: "itemRollup" }));
    dispatch(fetchRFPRollupItems(allFilters));
    dispatch(setSelectedRFPItem({ itemId: null }));
    dispatch(setRFPRollupCSVFalse());
    dispatch(clearRFPRollupReport());
  };

  const handleFilteredPOFetch = () => {
    dispatch(setChips({ filterType: "history" }));
    dispatch(fetchFilteredPOHistory(allFilters));
    dispatch(setPOTriggerCSVFalse());
    dispatch(clearPOReport());
  };

  const handlePORollupFetch = () => {
    dispatch(setChips({ filterType: "itemRollup" }));
    dispatch(fetchPOItemRollups(allFilters));
    dispatch(setItemRollupIdSelection({ selectedItems: [] }));
    dispatch(setPORollupCSVFalse());
    dispatch(clearPORollupReport());
  };

  const handleComplianceRulesFetch = () => {
    dispatch(setChips({ filterType: "compliance" }));
    dispatch(fetchTriggeredRules(allFilters));
    dispatch(setTriggeredRuleCSVFalse());
    dispatch(clearCompItemReport());
  };

  const handleC2MHistoryFetch = () => {
    dispatch(setChips({ filterType: "c2m-order-history" }));
    dispatch(fetchC2MOrderHistory(allFilters));
    dispatch(setTriggerC2MCSVFalse());
    dispatch(resetHistoryReport());
    resetSequenceNum();
    resetOrderNum();
    resetC2mDistributor();
    resetC2mTerritory();
    resetC2mProgramName();
    resetC2mBrands();
    resetC2mBusinessUnit();
  };

  const handleC2MItemHistoryFetch = () => {
    dispatch(setChips({ filterType: "c2m-item-history" }));
    dispatch(fetchC2MItemHistory(allFilters));
    resetSequenceNum();
    resetC2mName();
    resetC2mItemType();
    resetC2mProgramTheme();
    resetC2mBrands();
  };

  const handleFilteredPlanningProgramsFetch = () => {
    dispatch(setChips({ filterType: "planningTool" }));
    dispatch(fetchFilteredPrograms(allFilters));
    resetSequenceNum();
    resetWorkfrontId();
    resetProgramId();
    resetProgramName();
    resetCouponBarcodeId();
  };

  const handleFilteredAnaplanProgramsFetch = () => {
    dispatch(setChips({ filterType: "planningToolActivate" }));
    dispatch(fetchFilteredAnaplanPrograms(allFilters));
    resetCodeOrName();
  };

  const handleRFQsFetch = () => {
    dispatch(setChips({ filterType: "RFQ" }));
    dispatch(fetchRFQs(allFilters));
    resetSequenceNum();
    resetItemDesc();
  };

  const handleQuotesFetch = () => {
    dispatch(setChips({ filterType: "quotes" }));
    dispatch(fetchQuotes(allFilters));
    resetSupplierQuoteId();
    resetSequenceNum();
  };

  const historySearchMap = {
    orders: handleOrderHistoryFetch,
    rollup: handleOrderSetFetch,
    approvals: handleOrderSetFetch,
    rfp: handleFilteredRFPFetch,
    po: handleFilteredPOFetch,
  };

  useEffect(() => {
    if (setToClear) {
      resetAllFilters();
    }
  }, [setToClear, resetAllFilters, allFilters, filterType, dispatch]);

  useEffect(() => {
    if (sorted || fetchCurrent) {
      if (filterType === "history-orders") {
        if (allFilters.groupBy === "order") {
          dispatch(fetchOrderHistory(allFilters));
        } else {
          dispatch(fetchOrderVariantHistory(allFilters));
        }
        dispatch(setOrderHistoryCSVFalse());
        dispatch(clearOrderHistoryReports());
      }
      if (
        filterType === "history-rollup" ||
        filterType === "history-approvals"
      ) {
        if (filterType !== "history-rollup") {
          if (allFilters.groupBy === "order") {
            dispatch(fetchFilteredOrderSets(allFilters));
          } else {
            dispatch(fetchFilteredOrderSetVariants(allFilters));
          }
        }
        dispatch(setTriggerCSVFalse());
        dispatch(clearOrderSetReports());
      }
      if (
        filterType === "item-inventory" ||
        filterType === "item-onDemand" ||
        filterType === "item-all" ||
        filterType === "item-archive"
      ) {
        let updatedFilters = { ...allFilters };
        if (currentTerritoryId && allFilters.exclusiveTerritory.length > 0) {
          dispatch(
            updateSingleFilter({ filter: "exclusiveTerritory", value: [] })
          );
          updatedFilters.exclusiveTerritory = [];
          dispatch(setChips({ filterType: filterType }));
        }
        dispatch(fetchFilteredItems(updatedFilters));
        dispatch(clearItemSelection());
        if (filterType === "item-inventory") {
          dispatch(clearItemReport());
          dispatch(setItemCSVFalse());
        }
      }
      if (filterType === "history-rfp") {
        dispatch(fetchFilteredRFPHistory(allFilters));
      }
      if (filterType === "history-po") {
        dispatch(fetchFilteredPOHistory(allFilters));
        dispatch(setPOTriggerCSVFalse());
        dispatch(clearPOReport());
      }
      if (filterType === "itemRollup-rfp") {
        dispatch(fetchRFPRollupItems(allFilters));
        dispatch(setSelectedRFPItem({ itemId: null }));
        dispatch(setRFPRollupCSVFalse());
        dispatch(clearRFPRollupReport());
      }
      if (filterType === "itemRollup-po") {
        dispatch(fetchPOItemRollups(allFilters));
        dispatch(setItemRollupIdSelection({ selectedItems: [] }));
        dispatch(setPORollupCSVFalse());
        dispatch(clearPORollupReport());
      }
      if (filterType === "compliance-rules") {
        dispatch(fetchRules(allFilters));
      }
      if (filterType === "compliance-items") {
        dispatch(fetchTriggeredRules(allFilters));
        dispatch(setTriggeredRuleCSVFalse());
        dispatch(clearCompItemReport());
      }
      if (filterType === "user-settings") {
        dispatch(fetchFilteredUsers({}));
      }
      if (filterType === "c2m-order-history") {
        dispatch(fetchC2MOrderHistory(allFilters));
        dispatch(setTriggerC2MCSVFalse());
        dispatch(resetHistoryReport());
      }
      if (filterType === "c2m-item-history") {
        dispatch(fetchC2MItemHistory(allFilters));
      }
      if (filterType === "planningTool") {
        dispatch(fetchFilteredPrograms(allFilters));
      }
      if (filterType === "RFQ") {
        dispatch(fetchRFQs(allFilters));
      }
      if (filterType === "quotes") {
        dispatch(fetchQuotes(allFilters));
      }
      if (sorted) {
        dispatch(setSorted());
      }
      if (fetchCurrent) {
        dispatch(setFetchCurrent());
      }
    }
  }, [
    sorted,
    fetchCurrent,
    dispatch,
    filterType,
    allFilters,
    currentTerritoryId,
  ]);

  const clearMap = {
    itemNumber: resetSequenceNum,
    rfpNum: resetrfpNum,
    poNum: resetPoNum,
    itemDesc: resetItemDesc,
    itemCommentAndSubType: resetItemCommentAndSubType,
    orderNum: resetOrderNum,
    poInvoiceNumber: resetPOInvoiceNumber,
    poExternalSapId: resetPOExternalSapId,
    supplierReference: resetSupplierReference,
    c2mTerritory: resetC2mTerritory,
    c2mProgramName: resetC2mProgramName,
    c2mBrands: resetC2mBrands,
    c2mBusinessUnit: resetC2mBusinessUnit,
    c2mDistributor: resetC2mDistributor,
    c2mName: resetC2mName,
    c2mItemType: resetC2mItemType,
    c2mProgramTheme: resetC2mProgramTheme,
    supplierQuoteId: resetSupplierQuoteId,
    programName: resetProgramName,
    workfrontId: resetWorkfrontId,
    programId: resetProgramId,
    couponBarcodeId: resetCouponBarcodeId,
    anaplanCode: resetAnaplanCode,
    budgetId: resetBudgetId,
  };

  useEffect(() => {
    if (clearFreeType && clearFreeType.length > 0) {
      clearFreeType.forEach((type) => {
        clearMap[type]();
      });
      dispatch(setClearFreeType({ value: false }));
    }
  });

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div
          style={{
            position: "relative",
            padding: "20px",
            height: "Calc(100% - 87px)",
            marginTop: "84px",
          }}
        >
          <Typography
            className={classes.titleText}
            style={{ fontWeight: "500" }}
          >
            Filters:
          </Typography>
          <Divider />
          {filterType &&
            filterType.includes("item-") &&
            filterType !== "c2m-item-history" &&
            filterType !== "item-onDemand" && (
              <FiltersItems
                reset={reset}
                setReset={setReset}
                handleFilters={handleFilters}
                classes={classes}
                itemFilterType={filterType.split("-")[1]}
              />
            )}
          {filterType === "item-onDemand" && (
            <FiltersOnDemandItems
              reset={reset}
              setReset={setReset}
              handleFilters={handleFilters}
              classes={classes}
              itemNumber={itemNumber}
              bindSequenceNum={bindSequenceNum}
              itemDesc={itemDesc}
              bindItemDesc={bindItemDesc}
              handleSearch={handleFilteredItemFetch}
            />
          )}
          {filterType && filterType === "c2m-order-history" && (
            <FiltersC2MHistory
              classes={classes}
              itemNumber={itemNumber}
              bindSequenceNum={bindSequenceNum}
              orderNum={orderNum}
              bindOrderNum={bindOrderNum}
              c2mTerritory={c2mTerritory}
              bindC2mTerritory={bindC2mTerritory}
              c2mProgramName={c2mProgramName}
              bindC2mProgramName={bindC2mProgramName}
              c2mBrands={c2mBrands}
              bindC2mBrands={bindC2mBrands}
              c2mBusinessUnit={c2mBusinessUnit}
              bindC2mBusinessUnit={bindC2mBusinessUnit}
              c2mDistributor={c2mDistributor}
              bindC2mDistributor={bindC2mDistributor}
              handleFilters={handleFilters}
              handleSearch={handleC2MHistoryFetch}
            />
          )}
          {filterType && filterType === "c2m-item-history" && (
            <FiltersC2MItemHistory
              classes={classes}
              itemNumber={itemNumber}
              bindSequenceNum={bindSequenceNum}
              c2mBrands={c2mBrands}
              bindC2mBrands={bindC2mBrands}
              c2mName={c2mName}
              bindC2mName={bindC2mName}
              c2mItemType={c2mItemType}
              bindC2mItemType={bindC2mItemType}
              c2mProgramTheme={c2mProgramTheme}
              bindC2mProgramTheme={bindC2mProgramTheme}
              handleSearch={handleC2MItemHistoryFetch}
            />
          )}
          {filterType &&
            filterType.includes("history") &&
            filterType !== "c2m-order-history" &&
            filterType !== "c2m-item-history" && (
              <FiltersHistory
                reset={reset}
                setReset={setReset}
                handleFilters={handleFilters}
                classes={classes}
                itemNumber={itemNumber}
                bindSequenceNum={bindSequenceNum}
                rfpNum={rfpNum}
                bindrfpNum={bindrfpNum}
                poNum={poNum}
                bindPoNum={bindPoNum}
                orderNum={orderNum}
                bindOrderNum={bindOrderNum}
                poInvoiceNumber={poInvoiceNumber}
                bindPOInvoiceNumber={bindPOInvoiceNumber}
                poExternalSapId={poExternalSapId}
                bindPOExternalSapId={bindPOExternalSapId}
                supplierReference={supplierReference}
                bindSupplierReference={bindSupplierReference}
                anaplanCode={anaplanCode}
                bindAnaplanCode={bindAnaplanCode}
                bindBudgetId={bindBudgetId}
                handleSearch={historySearchMap[filterType.split("-")[1]]}
                historyType={filterType.split("-")[1]}
              />
            )}
          {filterType && filterType.includes("compliance") && (
            <FiltersCompliance
              reset={reset}
              setReset={setReset}
              handleFilters={handleFilters}
              classes={classes}
              bindSequenceNum={bindSequenceNum}
              handleSearch={handleComplianceRulesFetch}
              complianceType={filterType.split("-")[1]}
            />
          )}
          {filterType && filterType.includes("program") && (
            <FiltersPrograms
              reset={reset}
              setReset={setReset}
              handleFilters={handleFilters}
              classes={classes}
              programType={filterType.split("-")[1]}
            />
          )}
          {filterType && filterType.includes("itemRollup") && (
            <FiltersItemRollup
              reset={reset}
              setReset={setReset}
              handleFilters={handleFilters}
              classes={classes}
              itemNumber={itemNumber}
              bindSequenceNum={bindSequenceNum}
              handleSearch={
                filterType.split("-")[1] === "rfp"
                  ? handleRFPRollupFetch
                  : handlePORollupFetch
              }
              rollupType={filterType.split("-")[1]}
            />
          )}
          {filterType && filterType === "planningTool" && (
            <FiltersPlanningTool
              reset={reset}
              setReset={setReset}
              handleFilters={handleFilters}
              handleSearch={handleFilteredPlanningProgramsFetch}
              classes={classes}
              itemNumber={itemNumber}
              bindSequenceNum={bindSequenceNum}
              programName={programName}
              bindProgramName={bindProgramName}
              workfrontId={workfrontId}
              bindWorkfrontId={bindWorkfrontId}
              programId={programId}
              bindProgramId={bindProgramId}
              couponBarcodeId={couponBarcodeId}
              bindCouponBarcodeId={bindCouponBarcodeId}
            />
          )}
          {filterType && filterType === "planningToolActivate" && (
            <FiltersPlanningToolActivate
              reset={reset}
              setReset={setReset}
              handleFilters={handleFilters}
              handleSearch={handleFilteredAnaplanProgramsFetch}
              classes={classes}
              codeOrName={codeOrName}
              bindCodeOrName={bindCodeOrName}
            />
          )}
          {filterType && filterType === "RFQ" && (
            <FiltersRFQs
              reset={reset}
              setReset={setReset}
              handleFilters={handleFilters}
              classes={classes}
              itemNumber={itemNumber}
              bindSequenceNum={bindSequenceNum}
              programName={programName}
              bindProgramName={bindProgramName}
              itemDesc={itemDesc}
              bindItemDesc={bindItemDesc}
              handleSearch={handleRFQsFetch}
            />
          )}
          {filterType && filterType === "quotes" && (
            <FiltersQuotes
              reset={reset}
              setReset={setReset}
              handleFilters={handleFilters}
              classes={classes}
              itemNumber={itemNumber}
              bindSequenceNum={bindSequenceNum}
              supplierQuoteId={supplierQuoteId}
              bindSupplierQuoteId={bindSupplierQuoteId}
              handleSearch={handleQuotesFetch}
            />
          )}
          {filterType === "budgets" && <BudgetFilters />}
        </div>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
