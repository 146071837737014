// const transformAttributes = (transformers, data) => {
//   if (!data) return data;
import { isAxiosError } from "axios";
import { kebabCase } from "lodash";

import consumeAxiosError from "@utils/highlightio/consumeAxiosError";

//   if (Array.isArray(data))
//     return data.map((entity) => transformAttributes(transformers, entity));

//   if (typeof transformers[data.type] === "function") {
//     const transformedAttributes = transformers[data.type](data.attributes);
//     return {
//       ...data,
//       attributes: { ...data.attributes, ...transformedAttributes },
//     };
//   }
//   return data;
// };

// // Recursively applies a function to keys of an object and its children
// const transformKeys = (obj, tf) => {
//   if (typeof obj !== "object" || !obj) return obj;
//   if (Array.isArray(obj)) return obj.map((i) => transformKeys(i, tf));

//   const mappedObj = Object.entries(obj).reduce((a, [key, value]) => {
//     const tfValue = transformKeys(value, tf);
//     a[tf(key)] = tfValue;
//     return a;
//   }, {});
//   return mappedObj;
// };

// export const transformResponseBody = (transformers) => (responseBody) => ({
//   ...responseBody,
//   data: transformAttributes(transformers, responseBody.data),
//   included: transformAttributes(transformers, responseBody.included),
// });

// export const transformEntity = (transformers) => (entity) => {
//   if (typeof transformers[entity.type] === "function")
//     return { ...entity, ...transformers[entity.type](entity) };

//   return entity;
// };

export const stripRelationshipData = (body) => {
  let relationships: Record<string, any> = body.data.relationships;
  if (!relationships) return body;

  relationships = Object.entries(relationships)
    .filter(([_, value]) => Array.isArray(value.data))
    .map(([key, value]): [string, { data: any }] => [
      key,
      { data: value.data.map(({ id, type }) => ({ id, type })) },
    ])
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  return {
    data: {
      ...body.data,
      relationships,
    },
  };
};

const jsonApiErrorMessage = (errors) =>
  !errors
    ? ""
    : Array.isArray(errors)
    ? errors.map((e) => e.detail.replace(/^Base /, "")).join("\n")
    : errors.detail.replace(/^Base /, "");

export const axiosOrApiError = (err) => {
  if (isAxiosError(err) && err.response) {
    consumeAxiosError(err, "apiClient");
    return {
      status: err.response.status,
      statusText: err.response.statusText,
      message: `${err.message} - ${
        err.response.statusText
      }\n${jsonApiErrorMessage(err.response.data.errors)}`,
      data: err.response.data,
    };
  }
  return err;
};
function queryFormat(value, key) {
  if (value !== null && Array.isArray(value))
    return value.map((v) => queryFormat(v, key + "[]")).join("&");
  else if (value !== null && typeof value === "object")
    return query(value, key);
  else return encodeURIComponent(key) + "=" + encodeURIComponent(value);
}

/**
 * Constructs a URL query string for JSON:API parameters
 *
 * @param {Object} [params] Parameters to parse
 * @param {string} [prefix] Prefix for nested parameters - used internally
 * @returns {string} URL query string
 *
 * @example
 * query({
 *   filter: {
 *     slug: 'cowboy-bebop',
 *     title: {
 *       value: 'foo'
 *     }
 *   }
 *  sort: '-id'
 * })
 * // filter%5Bslug%5D=cowboy-bebop&filter%5Btitle%5D%5Bvalue%5D=foo&sort=-id
 */
export function query(params, prefix = null) {
  const str: string[] = [];

  for (const param in params) {
    if (params[param] !== null && params[param] !== undefined) {
      str.push(
        queryFormat(
          params[param],
          prefix ? `${prefix}[${kebabCase(param)}]` : kebabCase(param)
        )
      );
    }
  }
  return str.filter(Boolean).join("&");
}
