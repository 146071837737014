/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { ReactNode, useState } from "react";
import SwipeableViews from "react-swipeable-views";

import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";

import ImageDownloadMenuButton from "./Utility/ImageDownloadMenuButton";

const Carousel = ({
  children,
  startIndex,
  imageIdsOrUrls,
}: {
  children: ReactNode[];
  startIndex?: number;
  imageIdsOrUrls: string[];
}) => {
  const [activeStep, setActiveStep] = useState(startIndex ?? 0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div
      tw="flex items-center flex-col gap-2"
      css={{
        ".MuiMobileStepper-dot": tw`bg-neutral-300`,
        ".MuiMobileStepper-dotActive": tw`bg-neutral-600`,
      }}
    >
      <div tw="relative">
        <SwipeableViews
          onChangeIndex={handleStepChange}
          index={activeStep}
          enableMouseEvents
        >
          {React.Children.map(children, (child) => (
            <div tw="flex justify-center h-full items-center">{child}</div>
          ))}
        </SwipeableViews>
        <div tw="w-full flex justify-center">
          <ImageDownloadMenuButton
            cloudinaryIdsOrImageUrls={imageIdsOrUrls}
            index={activeStep}
          />
        </div>
      </div>
      {children.length > 1 && (
        <div tw="flex justify-between w-full max-w-sm">
          <MobileStepper
            steps={children.length}
            position="static"
            activeStep={activeStep}
            backButton={
              <IconButton
                tw="text-neutral-600 disabled:text-neutral-300"
                onClick={() => handleStepChange(activeStep - 1)}
                disabled={activeStep === 0}
              >
                <ArrowBackIosNew />
              </IconButton>
            }
            nextButton={
              <IconButton
                tw="text-neutral-600 disabled:text-neutral-300"
                onClick={() => handleStepChange(activeStep + 1)}
                disabled={activeStep === children.length - 1}
              >
                <ArrowForwardIos />
              </IconButton>
            }
            tw="flex w-full"
          />
        </div>
      )}
    </div>
  );
};

export default Carousel;
