/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { CircularProgress } from "@mui/material";

import _ from "lodash";
import { setError } from "src/redux/slices/errorSlice";

import { ResourceError } from "@features/errors";
import { useItemProgramListQuery } from "@features/programs/data";
import { OrderSet } from "@models/OrderSet";
import { VariantCategoryName } from "@models/VariantOption";

import { useCreateOrderSetMutation } from "../queries";
import PreOrderProgramVariantSelection from "./PreOrderProgramVariantSelection";

const PreOrderProgramInit = ({
  programId,
  orderWindowId,
  onSuccess,
}: {
  programId: string;
  orderWindowId: string;
  onSuccess: (orderSet: OrderSet) => void;
}) => {
  const dispatch = useDispatch();
  const [createTriggered, setCreateTriggered] = useState(false);
  const createOrderSetMutation = useCreateOrderSetMutation();

  const creatFnRef = useRef(createOrderSetMutation.mutate);
  creatFnRef.current = createOrderSetMutation.mutate;

  const handleCreateOrderSet = useCallback(
    (sportsVariantOptionIds: string[]) => {
      setCreateTriggered(true);
      creatFnRef.current(
        {
          type: "pre-order",
          programId,
          orderWindowId,
          sportsVariantOptionIds,
        },
        {
          onSuccess: onSuccess,
          onError: (err) =>
            dispatch(
              setError({
                error: err.message,
                source: "Create Pre-Order Order Set",
              })
            ),
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, programId, orderWindowId]
  );

  const {
    data = [],
    error,
    isLoading,
  } = useItemProgramListQuery({
    filter: {
      programIds: programId,
      orderCalendarMonthIds: orderWindowId,
      isActive: true,
      rtaDeployment: true,
    },
  });

  const sportVariantOptions = useMemo(
    () =>
      _(data)
        .flatMap((ip) =>
          ip.item.variants!.flatMap((v) => v.selectedVariantOptions)
        )
        .filter((vo) => vo.variantCategory.name === VariantCategoryName.Sports)
        .uniqBy("id")
        .sortBy("name")
        .value(),
    [data]
  );

  useEffect(() => {
    if (isLoading || createTriggered) return;
    if (!sportVariantOptions.length) {
      handleCreateOrderSet([]);
      return;
    }
  }, [isLoading, sportVariantOptions, handleCreateOrderSet, createTriggered]);

  if (error) return <ResourceError error={error} />;

  if (isLoading)
    return (
      <div tw="p-6 text-lg text-neutral-400">
        Loading Order Set Items... <CircularProgress size={24} tw="ml-4" />
      </div>
    );

  if (createOrderSetMutation.isPending)
    return (
      <div tw="p-6 text-lg text-neutral-400">
        Creating Order Set... <CircularProgress size={24} tw="ml-4" />
      </div>
    );

  return (
    <PreOrderProgramVariantSelection
      sportVariantOptions={sportVariantOptions}
      handleCreate={handleCreateOrderSet}
    />
  );
};

export default PreOrderProgramInit;
